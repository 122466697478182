import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import { CircularProgress } from '@material-ui/core'
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField'
import SelectField from '../SharedComponents/SelectField/SelectField'
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea'
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel'
import DateField from '../../../caster-animation/components/SharedComponents/DateField/DateField'
// import { ssnOptions, initialGuardianList } from './Config'
import { initialAliasConfig, representativePersonalLimit, emailConfig, initialRepresentativeEmail, initialRepresentativePhone, initialRepresentativeAddress, phoneConfig } from './config';
import SelectAndInputCombine from '../../Common/SelectAndInputCombine/SelectAndInputCombine'
import Radio from "@material-ui/core/Radio";
import AddGuardian from "../../Common/AddGuardian/AddGuardian"
import SearchSelectField from '../SharedComponents/SearchSelectField'
import AliasComponent from '../../Common/AliasComponent/AliasComponent'
import PhoneComponent from '../../Common/DefaultPhoneComponent/PhoneComponent'
import EmailComponent from '../../Common/Email/EmailComponent'
import AddressComponent from '../../Common/AddressComponent/AddressComponent'
import { validateCharNumOnlyWithSpace, validateEmail, validateNumHyphenOnly, validatePlusCharacter, validateAllPhone } from '../../Common/Helper';
import messages from "../../Common/Messages.json"
import sizeLimits from '../../Common/SizeLimits.json';
import InputSelectRadio from '../../Common/InputSelectRadioInline/InputSelectRadio'
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../../contexts/UserContext'
import SearchFieldWithAddValue from "../SharedComponents/SearchFieldWithAddValue";
class RepresentativePersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genderOptions: [],
            ethnicityOptions: [],
            countryOptions: [],
            socialOptions: [],
            emailOptions: [],
            phoneOptions: [],
            // ssnOptions: [...ssnOptions],
            addressSelected: {},
            showDod: false,
            setPopOver: null,
            showImmigration: false,
            isMinor: false,
            showGuardianPopup: false,
            guardianSelected: {},
            showAddressPopup: false,
            // guardians_list: [...initialGuardianList]

        }
    }
    componentDidMount() {
        // this.getLookupValues('Gender');
        // this.getLookupValues('Ethnicity');
        // this.getLookupValues('Country');
        this.getLookupValues('Phone');
        this.getLookupValues('Email');
        this.getLookupValues('Social');
        this.getRepOccupation();
        // this.setState({
        //     representativeDetails: this.props.representativeDetails
        // })

    }

    getRepOccupation = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=REPRESENTATIVE_OCCUPATION&searchString=null`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.occupation, label: i?.occupation, id: i?.id || null }))
                    this.setState({ occupationOptions: formattedList })
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         representativeDetails: nextProps.representativeDetails
    //     });

    // }
    // componentDidUpdate() {
    //     console.log("DidupdateTalent")
    // }
    showPopup(type, id, data = {}) {
        let htmlElement = document.getElementById(id);
        this.setState({ setPopOver: htmlElement, [`${type}Selected`]: { ...data }, })
        switch (type) {
            case "guardian":
                this.setState({ showGuardianPopup: true })
                break;
            case "address":
                this.setState({ showAddressPopup: true })
                break;

            default:
                break;
        }

    }
    onClosePopup(type) {
        this.setState({ setPopOver: null, [`${type}Selected`]: {} })
        switch (type) {
            case "guardian":
                this.setState({ showGuardianPopup: false })
                break;
            case "address":
                this.setState({ showAddressPopup: false })
                break;
            default:
                break;
        }
    }

    getFullAddressForLocation(item) {
        let { address, city, state, country, zip } = item;
        if (state?.includes('-')) {
            let splitArray = state?.split("-")
            state = splitArray[0]
        }
        return <div className="repAddress">
            {address ? <p> {address} </p> : ''}
            {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
            {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
        </div>
    }

    getFullAddress(item) {
        let { address, city, state, country, zip } = item;
        if (state?.includes('-')) {
            let splitArray = state?.split("-")
            state = splitArray[1]
        }
        return <div className="repAddress">
            {address ? <p> {address} </p> : ''}
            {<p>{`${city ? city : ''} ${state ? city ? ', ' + state : state : ''} ${zip ? state ? ' ' + zip : city ? ', ' + zip : zip : ''}`}</p>}
            {country ? (country !== "United States" && country !== "USA" && country !== "US") ? <p> {country} </p> : '' : ''}
        </div>
        // return `${address ? address + '\n' : ''}  ${city ? city + ',' : ''} ${state ? state + ',' : ''} ${zip ? zip + '\n' : ''} ${zip ? country ? country : '' :  country ?  '\n' + country  : ''}`;
    }

    onAddressSave(address) {
        console.log(address);
        this.onClosePopup('address')
        this.props?.onAddressChange(this.state.addressSelected?.representative_address_id, 'address_list', address)
    }
    getLookupValues(type) {
        // casterFeatureLookup
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value }))
                    if (type == 'Gender') {
                        this.setState({ genderOptions: formattedList })
                    } else if (type == 'Ethnicity') {
                        this.setState({ ethnicityOptions: formattedList })
                    } else if (type == 'Country') {
                        this.setState({ countryOptions: formattedList })
                    }
                    else if (type == 'Email') {
                        this.setState({ emailOptions: formattedList })
                    }
                    else if (type == 'Phone') {
                        this.setState({ phoneOptions: formattedList })
                    } else if (type == 'Social') {
                        this.setState({ socialOptions: formattedList })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })

    }
    toggleCollapse(id) {
        let updatedGuradian = JSON.parse(JSON.stringify(this.state?.guardians_list));
        let alterObjectId = updatedGuradian?.findIndex(i => i.talent_guardians_id == id);
        if (alterObjectId != -1) {
            updatedGuradian[alterObjectId].showCollapsible = !updatedGuradian[alterObjectId].showCollapsible;
        }
        this.setState({ guardians_list: updatedGuradian })
    }

    handleNavs = () => {
        this.props.navFuns.handleHideRepresentativePopup();
        this.props.navFuns.handleShowAgencyPopup(this.props?.selectedRep?.value, this.props?.selectedRep?.text);
    }

    render() {
        return (
            <MDBContainer className='RepresentativePersonalContainer'>
                {this.props?.isLoading ?
                    <CircularProgress />
                    :
                    <>
                        {this.props?.isEditing ?
                            <MDBRow>
                                <MDBCol>
                                    <BasicTextField
                                        id="firstName"
                                        label="First Name"
                                        isAlphabetVar={true}
                                        value={this.props?.representativeDetails?.first_name}
                                        handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('first_name', value)}
                                        onChange={(e) => this.props?.handleGeneralEdit('first_name', e.target?.value)}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <BasicTextField
                                        id="middleName"
                                        label="Middle Name"
                                        isAlphabetVar={true}
                                        value={this.props?.representativeDetails?.middle_name}
                                        handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('middle_name', value)}
                                        onChange={(e) => this.props?.handleGeneralEdit('middle_name', e.target?.value)}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <BasicTextField
                                        id="lastName"
                                        label="Last Name"
                                        isAlphabetVar={true}
                                        value={this.props?.representativeDetails?.last_name}
                                        handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('last_name', value)}
                                        onChange={(e) => this.props?.handleGeneralEdit('last_name', e.target?.value)}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <BasicTextField
                                        id="suffixTalent"
                                        label="Suffix"
                                        isAlphabetVar={true}
                                        value={this.props?.representativeDetails?.suffix}
                                        handleAlphabetVariantSelect={(value) => this.props?.handleGeneralEdit('suffix', value)}
                                        onChange={(e) => this.props?.handleGeneralEdit('suffix', e.target?.value)}
                                    />
                                </MDBCol>
                            </MDBRow> :
                            <MDBRow>
                                <BasicLabel text={"Name"} />
                                <div className={`displayName`}>{`${this.props?.representativeDetails?.first_name ? this.props?.representativeDetails?.first_name : ''} ${this.props?.representativeDetails?.middle_name ? this.props?.representativeDetails?.middle_name + ' ' : ' '} ${this.props?.representativeDetails?.last_name ? this.props?.representativeDetails?.last_name : ''} ${this.props?.representativeDetails?.suffix ? this.props?.representativeDetails?.suffix : ''}` || '-'}</div>
                            </MDBRow>
                        }
                        {this.props?.isEditing &&
                            <MDBRow>
                                <AliasComponent
                                    aliasDetails={this.props?.representativeDetails?.akas}
                                    onAliasSave={this.props?.onAliasSave.bind(this)}
                                    module_type={"Representative"}
                                    initialAliasConfig={initialAliasConfig}
                                />
                            </MDBRow>
                        }
                        <MDBRow>
                            <MDBCol md={12}>
                                <div className="headerText">Personal</div>
                            </MDBCol>
                            <MDBRow className="personal">
                                <MDBCol md={3} className="title">
                                    <BasicTextField
                                        id="title"
                                        label={"Title"}
                                        // isMandatory={true}
                                        // showMandatory={this.state.postInitiated || false}
                                        value={this.props?.representativeDetails?.title}
                                        onChange={(e) => this.props?.handleGeneralEdit('title', e.target?.value)}
                                        showAsLabel={!this.props?.isEditing}
                                        stateLabel={!this.props?.isEditing}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    <SelectField
                                        id="occupation"
                                        label="Occupation"
                                        options={this.state?.occupationOptions}
                                        value={this.props?.representativeDetails?.occupation}
                                        onChange={(e) => this.props?.handleGeneralEdit('occupation', e.target?.value)}
                                        showAsLabel={!this.props?.isEditing}
                                        stateLabel={!this.props?.isEditing}
                                    />
                                </MDBCol>
                                {/* <MDBCol md={3}>
                                    <BasicLabel text={"DOB"} />
                                    {/* <DateField
                                    id="dob"
                                    label="DOB"
                                    value={this.props?.representativeDetails?.birth_date}
                                    onChange={(e) => this.props?.handleGeneralEdit('birth_date', e.target?.value)}
                                    showAsLabel={!this.props?.isEditing}
                                /> */}
                                {/* <SelectField
                                    id="citizenship"
                                    label="Citizenship"
                                    options={this.state?.cityOptions}
                                    value={this.props?.talentDetails?.citizen_of}
                                    onChange={(e) => this.props?.handleGeneralEdit('citizen_of', e.target?.value)}
                                    showAsLabel={!this.props?.isEditing}
                                    stateLabel={!this.props?.isEditing}
                                /> */}
                                {/* </MDBCol> */}
                                <MDBCol md={3}>
                                    <BasicTextField
                                        id="representativeType"
                                        label="Representative Type"
                                        value={this.props?.representativeDetails?.type}
                                        onChange={(e) => this.props?.handleGeneralEdit('type', e.target?.value)}
                                        showAsLabel={!this.props?.isEditing}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={3} className="company">
                                <BasicLabel text={"Company"} />
                                {/* <MDBCol className="editCompany"> */}
                                {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                <MDBIcon icon="pen"
                                    className='transform_arroww'
                                    disabled={(this.props?.selectedRep!==undefined && this.props?.selectedRep!==null && this.props?.selectedRep?.value!==null)?false:true}
                                    onClick={(this.props?.selectedRep!==undefined && this.props?.selectedRep!==null && this.props?.selectedRep?.value!==null) ? () => this.handleNavs() : null}
                                />}

                                {/* <BasicButton variant="outlined"
                                    text={"Edit Company"}
                                    type="inline"
                                    // ref={this.submitButtonRef}
                                     icon={"pen"}
                                    disabled={this.props?.selectedRep === null}
                                    onClick={() => this.handleNavs()}
                                /> */}
                                {/* <MDBIcon icon="trash" /> */}
                                {/* </MDBCol> */}
                                {!this.props?.isEditing ?
                                    <div className="showAsLabel">{this.props?.representativeDetails?.agency_name || ""} </div>
                                    :
                                    // <SearchSelectField
                                    //     id={"company"}
                                    //     options={this.props?.company_options || []}
                                    //     width={'100%'}
                                    //     detail_selected={this.props?.selectedRep}
                                    //     valueSelected={(e, value) => this.props?.handleSelectedRep(value)}
                                    //     searchText={(e) => this.props?.getAgencyList("Agency", e.target.value)}
                                    //     multiple={false}
                                    //     searchSelect={true}
                                    //     placeholder={"- Search By Alias or Company to Add"}
                                    // />
                                    <SearchFieldWithAddValue
                                        key={"company"}
                                        id={"company"}
                                        placeholder={"- Search By Alias or Company to Add"}
                                        searchSelect={true}
                                        detail_selected={this.props?.selectedRep}
                                        options={this.props?.company_options || []}
                                        //onChange={(e, value) => this.props?.handleSelectedCrew(e, value, item, index)}
                                        onChange={(e, value) => this.props?.handleSelectedRep(value)}
                                        searchText={ev => {
                                            if (ev.target.value !== "" && ev.target.value !== null) {
                                                this.props?.getAgencyList("Agency", ev.target.value)
                                            }
                                            // else {
                                            //     this.setState({ isCrewFetch: false })
                                            // }
                                        }}
                                        // label={index == 0 ? "Assistants": ""}
                                        multiple={false}
                                        showAsLabel={!this.props.isEditing}
                                        newLabel={'Company'}
                                    />
                                }
                            </MDBCol>

                            <MDBCol md={3} className="location">
                                <BasicLabel text={"Location"} />
                                {!this.props?.isEditing ?
                                    <div className="showAsLabel">
                                        {
                                            this.props?.locationOptions?.find(location => { return location?.value === this.props?.selectedLocationId })?.label
                                        }
                                    </div>
                                    //     <div className="repPrimaryAddress">
                                    //     {this.props?.primaryLocation?.address ? <span> {this.props?.primaryLocation?.address}</span> : ''}
                                    //     {<span>
                                    //         {`${this.props?.primaryLocation?.city ?  `\n` + this.props?.primaryLocation?.city + `\n`  : ''} 
                                    //    ${this.props?.primaryLocation?.state ? this.props?.primaryLocation?.city ? ', ' + this.props?.primaryLocation?.state : this.props?.primaryLocation?.state : ''}
                                    //     ${this.props?.primaryLocation?.zip ? this.props?.primaryLocation?.state ? ' ' + this.props?.primaryLocation?.zip : this.props?.primaryLocation?.city ? ', ' + this.props?.primaryLocation?.zip : this.props?.primaryLocation?.zip : ''}`
                                    //   }
                                    //   </span>}
                                    //    {this.props?.primaryLocation?.country ? (this.props?.primaryLocation?.country !== "United States" && this.props?.primaryLocation?.country !== "USA" 
                                    //   && this.props?.primaryLocation?.country !== "US") ? <span> {  `\n` + this.props?.primaryLocation?.country} </span> : '' : ''}
                                    // </div>
                                    :
                                    this.props?.loadingLocation ?
                                        <CircularProgress className="LocationLoader" />
                                        :
                                        <SelectField
                                            id="location"
                                            options={this.props?.locationOptions}
                                            value={this.props?.selectedRep ? this.props?.selectedLocationId : null}
                                            onChange={(e) => this.props?.handleGeneralEdit('location', e.target?.value)}
                                            showAsLabel={!this.props?.isEditing}
                                            stateLabel={!this.props?.isEditing}
                                        />
                                    
                                }
                            </MDBCol>
                            <MDBCol>
                                <MDBRow className='addressRow'>
                                    <BasicLabel text={"Location Address"} />
                                    {/* <MDBCol md={3}>
                                        <span className='spanWithLeftBorder flRight'>{this.props?.selectedLocationAddress?.address_type}</span>
                                    </MDBCol> */}
                                    {!this.props?.isEditing ?
                                        <MDBCol md={6}>
                                            <div className={`spanWithLeftBorder`}>{
                                                this.props?.selectedLocationAddress?.address && this.getFullAddressForLocation(this.props?.selectedLocationAddress)
                                            }</div>
                                        </MDBCol>
                                        :
                                        <MDBCol md={6}>
                                            {this.props?.loadingLocation ?
                                                <CircularProgress className="LocationLoader" />
                                                :
                                                <div className={`spanWithLeftBorder`}>{
                                                    this.props?.selectedRep ? (this.props?.selectedLocationAddress?.address && this.getFullAddressForLocation(this.props?.selectedLocationAddress)) : null
                                                }</div>
                                            }
                                        </MDBCol>
                                    }
                                </MDBRow>
                            </MDBCol>

                        </MDBRow>
                        <MDBRow>
                            {/* <MDBCol className="editCompany">
                                <BasicButton variant="outlined"
                                    text={"Edit Company"}
                                    type="inline"
                                    // ref={this.submitButtonRef}
                                    // icon={"save"}
                                    disabled={this.props?.selectedRep === null}
                                    onClick={() => this.handleNavs()}
                                />
                               
                            </MDBCol> */}

                        </MDBRow>
                        <MDBRow className="phoneEmailRow">
                            <MDBCol md={6}>
                                {this.props?.representativeDetails?.phone_list?.filter(i => i?.is_delete == 0)?.map((item, index) => (
                                    <PhoneComponent
                                        radioGroupName='representativePhoneRadio'
                                        showAsLabel={!this.props?.isEditing}
                                        isEditing={this.props?.isEditing}
                                        objectItem={item}
                                        // label={index == 0 && !this.props?.isEditing ? "Phone" : ""}
                                        ext={true}
                                        inValidInput={"Please enter valid phone"}
                                        addCallback={(obj) => this.props?.addRemoveObject('phone_list', obj)}
                                        removeCallback={(id, key, value) => this.props?.onChangeObject('phone_list', id, key, value)}
                                        editCallback={(id, key, value) => this.props?.onChangeObject('phone_list', id, key, value)}
                                        selectOptions={this.state?.phoneOptions}
                                        showAdd={index == 0 && this.props?.isEditing ? true : false}
                                        showRemove={index != 0 && this.props?.isEditing ? true : false}
                                        showRadio={index != 0 && this.props?.isEditing ? true : false}
                                        inputValidateMethod={validateAllPhone}
                                        showLabel={index == 0 ? true : false}
                                        labelNew={"Phone"}
                                        addButton={index == 0 ? true : false}
                                    />
                                ))}
                            </MDBCol>
                            <MDBCol md={6}>
                                {this.props?.representativeDetails?.email_list?.filter(i => i?.is_delete == 0)?.map((emailItem, index) => (
                                    <InputSelectRadio
                                        radioGroupName='representativeEmailRadio'
                                        inputPlaceholder="Enter Email"
                                        showAsLabel={!this.props?.isEditing}
                                        isEditing={this.props?.isEditing}
                                        // label={index == 0 && !this.props?.isEditing ? "Email" : ""}
                                        objectItem={emailItem}
                                        addCallback={(emailObj) => this.props?.addRemoveObject('email_list', emailObj)}
                                        removeCallback={(id, key, value) => this.props?.onChangeObject('email_list', id, key, value)}
                                        editCallback={(id, key, value) => this.props?.onChangeObject('email_list', id, key, value)}
                                        selectOptions={this.state?.emailOptions}
                                        showAdd={index == 0 && this.props?.isEditing ? true : false}
                                        showRemove={index != 0 && this.props?.isEditing ? true : false}
                                        showRadio={index != 0 && this.props?.isEditing ? true : false}
                                        inputValidateMethod={validateEmail}
                                        hideActions={!this.props?.isEditing}
                                        showLabel={index == 0 ? true : false}
                                        labelNew={"Email"}
                                        addButton={index == 0 ? true : false}
                                        selectFieldLength={4}
                                        textFieldLength={8}
                                    />
                                ))}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="addressSocialRow">
                            <MDBCol md={6}>
                                {this.props?.isEditing ?
                                    <>
                                        <BasicLabel text={"Address"} />
                                        <div className='addLabel' id="newAddressBtn" onClick={() => this.showPopup('address', 'newAddressBtn', 'new')}>
                                            <span>
                                                <MDBIcon icon="plus-circle" />
                                                Add Address
                                            </span>
                                        </div>
                                    </>
                                    :
                                    <div className='headerText'>Address</div>
                                }
                                {this.props?.representativeDetails?.address_list?.filter(i => i?.is_delete == 0 || !i?.is_delete)?.map((item, index) => (
                                    <>
                                        <MDBRow className='addressRow'>
                                            <MDBCol md={3}>
                                                <span className='spanWithLeftBorder'>{item?.address_type}</span>
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <div className={`spanWithLeftBorder ${item?.is_primary == 1 ? 'primary' : ''}`}>{this.getFullAddress(item)}</div>
                                            </MDBCol>
                                            <MDBCol md={3} className='noPadding iconContainer'>
                                                {this.props?.isEditing ? (
                                                    <>
                                                        <MDBIcon icon="pen"
                                                            // className='transform_arrow'
                                                            onClick={() => this.showPopup('address', 'newAddressBtn', item)} />
                                                        <input
                                                            name='addressPrimary'
                                                            id={`radioAddress_${item?.representative_address_id}`}
                                                            size="small"
                                                            type="radio"
                                                            value={item?.representative_address_id}
                                                            defaultChecked={item?.is_primary == 1 ? true : false}
                                                            onChange={(e) => this.props?.onAddressChange(item?.representative_address_id, 'is_primary', e)}
                                                        />
                                                        <MDBIcon icon="times" onClick={() => this.props?.onAddressChange(item?.representative_address_id, 'is_delete', 1)} />
                                                    </>
                                                ) : <div><span className={(!this.props?.isEditing && item?.is_primary === 1) ? "primaryStarIcon" : ""}></span></div>}
                                            </MDBCol>
                                        </MDBRow>
                                    </>
                                ))}
                            </MDBCol>
                            {this.state?.showAddressPopup &&
                                <AddressComponent
                                    open={this.state?.showAddressPopup}
                                    setPopOver={this.state?.setPopOver}
                                    handleClose={this.onClosePopup.bind(this, 'address')}
                                    onSave={(address) => { this.onAddressSave(address) }}
                                    mode={this.state.addressSelected?.representative_address_id ? 'edit' : 'new'}
                                    id={this.state.addressSelected?.representative_address_id || null}
                                    selectedAddress={this.state?.addressSelected}
                                />
                            }
                            <MDBCol md={6} className="addressSocialColumn">
                                {!this.props?.isEditing ? <BasicLabel text="Social Media" /> : null}
                                {this.props?.representativeDetails?.social_list?.filter(i => i?.is_delete == 0)?.map((item, index) => (
                                    <InputSelectRadio
                                        radioGroupName='representativeSocialRadio'
                                        // inputPlaceholder="Enter Social Handle"
                                        showAsLabel={!this.props?.isEditing}
                                        // hideActions={!this.props?.isEditing}
                                        isEditing={this.props?.isEditing}
                                        hideActions={!this.props?.isEditing}
                                        // label={index == 0 && !this.props?.isEditing ? "Social Media" : ""}
                                        objectItem={item}
                                        addCallback={(obj) => this.props?.addRemoveObject('social_list', obj)}
                                        removeCallback={(id, key, value) => this.props?.onChangeObject('social_list', id, key, value)}
                                        editCallback={(id, key, value) => this.props?.onChangeObject('social_list', id, key, value)}
                                        selectOptions={this.state?.socialOptions}
                                        showAdd={index == 0 && this.props?.isEditing ? true : false}
                                        showRemove={index != 0 && this.props?.isEditing ? true : false}
                                        showRadio={index != 0 && this.props?.isEditing ? true : false}
                                        showLabel={index == 0 ? true : false}
                                        labelNew={"Handle"}
                                        addButton={index == 0 ? true : false}
                                        selectFieldLength={4}
                                        textFieldLength={8}
                                    />
                                ))}
                            </MDBCol>
                        </MDBRow>

                    </>
                }

            </MDBContainer>
        )
    }
}
export default withUserContext(RepresentativePersonal);