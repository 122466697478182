import React from 'react';
import TalentPersonal from '../Talent/TalentPersonal';
import RepresentativePersonal from './RepresentativePersonal';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import { validateEmail, maskSsn, getAddress, validateUSNumber, validateIntlNumber, validatePlusCharacter, validatePhoneNumber, ssnFormat, checkZipCode } from '../../Common/Helper';
// import {
//     talentInitialDetails, talentAddress, talentPhone,
//     talentEmail, talentAka, talentLegal, talentMinor, talentRepresentation, talentPersonalLimit,
//     talentContactLimit, addressConfig
// } from './Config';
import { emailConfig, initialAddressList, phoneObjData, initialObjectConfig, initialRepresentativeAddress, repTalentConfig, talentFilterConfig } from './config';
import { initialGuardianList, talentFCNavList } from '../Talent/Config'
import { withUserContext } from '../../../contexts/UserContext';
import sizeLimits from '../../Common/SizeLimits.json';
import './Representative.scss'
import RepresentativeTalent from './RepresentativeTalent';
import { handlePaginationList, updateTableSortConfigObject, tableSortList, applyFilterTable } from '../../Common/TableHelper';
import NewAgency from '../../Common/NewAgency/NewAgency';
import ModalComponent from '../../Common/DefaultModalLayout/Modal/Modal';
import { agencyNavList } from '../../../constants/NavList';
class RepresentativeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            mode: "new",
            navObj: {},
            isFetchingTalentDetails: false,
            config: JSON.parse(JSON.stringify({ ...repTalentConfig })),
            postInitiated: false,
            talentDetails: {},
            phoneIndex: 2,
            emailIndex: 2,
            addressIndex: 2,
            akaIndex: 2,
            loanoutIndex: 2,
            minorIndex: 2,
            repIndex: 2,
            checkedMultiPrimary: {
                email_list: false,
                phone_list: false,
                address_list: false,
                representation_list: false
            },
            filterInputs: [],
            aesKey: '',
            listProdFiles: {
                "signedURLType": "",
                "fileCategory": "",
                "tenantName": props?.userContext?.active_tenant?.tenant_name,
                "objectType": "",
                "fileName": "",
                "uploadLocation": "",
                "showName": "",
                "seasonName": "",
                "showId": null,
                "seasonId": null,
                "episodeId": null,
                "episodeName": "",
                "performerName": null,
                "performerId": null,
                "talentId": null
            },
            performerFiles: [],
            initialAgeValue: "",
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            filterList: JSON.parse(JSON.stringify({ ...talentFilterConfig })),
            page: 0,
            rowsPerPage: 10,
            primaryLocation: null,
            currentPrimaryLocation: null,
            selectedLocationId: null,
            selectedLocationAddress: null,
            showAgencyPopup: null,
            showNewAgencyPopup:null,
            loadingLocation:null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props?.tabValue !== state?.tabValue) {
            return {
                tabValue: props?.tabValue
            }
        }
    }

    validateErrorFlags = (representativeDetails) => {
        let canSubmit = ((representativeDetails?.first_name && representativeDetails?.last_name) &&
            (representativeDetails?.social_list?.length !== 0 ? representativeDetails?.social_list?.every(social => (social?.social_media_type && social?.social_media_account)) : true) &&
            (representativeDetails.email_list.length !== 0 ? (representativeDetails.email_list?.every(email => (email.email && email.email_type) ? true : false)) : true)

        )
        return canSubmit;
    }

    componentDidMount() {
        this.getRepresentativeDetails();
    }


    getAgencyList = (objectType, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.id, text: i?.agency_name, agency_name: i?.agency_name }))
                    // let formattedList = response?.data?.map(i => ({ value: i?.agency_name, text: i?.agency_name, id: i?.id }))
                    this.setState({ company_options: formattedList, backup_comp_options: response?.data })
                    console.log("post response", response);
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    getTalentList = (objectType, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.id, text: i?.talent_name }))
                    this.setState({ talent_options: formattedList, backup_talent_options: formattedList })
                    console.log("post response", response);
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    formateGuardianList(talentDetails) {
        let formattedGuardian = talentDetails?.guardians_list?.map(item => {
            let isPrimaryPhoneExist = item?.guardian_phone_list?.findIndex(i => i.is_primary == 1)
            let isPrimaryEmailExist = item?.guardian_email_list?.findIndex(i => i.is_primary == 1)
            item.showCollapsible = false;
            item.guardian_phone_list = item?.guardian_phone_list?.map((phone, index) => {
                if ((isPrimaryPhoneExist == index) || (isPrimaryPhoneExist == -1 && index == 0)) {
                    phone.showAsRow = true
                }
                else {
                    phone.showAsRow = false
                }
                phone.phone_with_ext = `+${phone.ext} ${phone.phone}`
                return phone;
            })
            item.guardian_email_list = item?.guardian_email_list?.map((email, index) => {
                if ((isPrimaryEmailExist == index) || (isPrimaryEmailExist == -1 && index == 0)) {
                    email.showAsRow = true
                }
                else {
                    email.showAsRow = false
                }
                return email;
            })
            return item;
        })
        return formattedGuardian;
    }
    guardianChange(id, subId, key, value) {
        let guradianList = JSON.parse(JSON.stringify(this.state?.talentDetails?.guardians_list));
        let guradianIndex = guradianList?.findIndex(i => i.talent_guardians_id == id);
        if (key == 'is_primary') {
            guradianList?.map(i => i.is_primary = 0);
            guradianList[guradianIndex][key] = 1;
        } else if (key == 'phone') {

        } else if (key == 'email') {

        }
        else {
            guradianList[guradianIndex][key] = value;
        }
        this.handleGeneralEdit('guardians_list', guradianList)

    }
    getPrimaryLocation() {
        let index = this.state?.representativeDetails?.address_list.findIndex(location => { return location.is_primary === 1 });
        console.log(index, this.state?.representativeDetails?.address_list[index])
        if (this.state?.representativeDetails?.address_list?.length > 0) {
            if (index) {
                // this.state.representativeDetails.address_list[index].location_name=this.state?.representativeDetails?.address_list[index]?.city
                return this.state?.representativeDetails?.address_list[index]
            } else {
                //  this.state.representativeDetails.address_list[0].location_name=this.state?.representativeDetails?.address_list[0]?.city
                return this.state.representativeDetails.address_list[0];
            }
        }
    }
    getAge = (birthDate) => {
        if (birthDate) {
            var today = new Date();
            let birthDateFormatted = new Date(birthDate)
            var age = today?.getFullYear() - birthDateFormatted?.getFullYear();
            var m = today?.getMonth() - birthDateFormatted?.getMonth();
            if (m < 0 || (m === 0 && today?.getDate() < birthDateFormatted?.getDate())) {
                age--;
            }
            age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
            this.handleGeneralEdit('age', birthDate ? age : 0);
            return age;
        } else {
            return 0
        }
    }

    setMinor = (age, birthDate) => {
        let minor = age < 18 ? 1 : 0;
        this.handleGeneralEdit('minor', birthDate ? minor : 0);
        return minor;
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            talent_list_to_display: handlePaginationList(newPage, this.state.rowsPerPage, this.state.current_list)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            talent_list_to_display: handlePaginationList(0, parseInt(event.target.value, 10), this.state.current_list)
        });
    }

    onChangeHandler = (type, value) => {
        let filteredUser = this.state.filterList
        filteredUser[type] = value
        this.setState({ filterList: filteredUser })
    }

    addFilterChangeHandler = (field, value) => {
        let newEntry = [`${field} :${value}`]
        let filterInputs = this.state.filterInputs
        let check = filterInputs?.filter((item, index) => {
            var keyValue = item.toString().split(":");
            if (keyValue[0].trim() === field) {
                filterInputs[index] = newEntry
                return item = newEntry
            }
            // var name = keyValue[0].trim();
            // var value = keyValue[1].trim().toLowerCase();
        })
        if (check?.length === 0) {
            filterInputs.push(newEntry)
        }
        var keyValuePair;
        filterInputs = filterInputs?.filter((item, index) => {
            keyValuePair = item.toString().split(":");
            if (keyValuePair[1] !== "") {
                return item
            }
        })
        this.setState({ filterInputs: filterInputs })
        let config = repTalentConfig
        let list = this.state.backup_talent_list
        let filteredTableData = this.applyFilterTable(config, list, filterInputs, false)
        let data = handlePaginationList(this.state.page, this.state.rowsPerPage, filteredTableData)
        this.setState({ talent_list: filteredTableData }, () => {
            this.setState({ talent_list_to_display: data, current_list: data })
        })
    }

    applyFilterTable = (config, data, filterValues, filtersNone) => {
        var finalList = [];
        var finalResult = data.slice();
        var primaryKey = config.primaryKey;
        if (!filtersNone) {

            filterValues.map((filter) => {
                var keyValue = filter.toString().split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();

                var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

                Object.keys(config?.headings).map((item) => {
                    console.log(config?.headings[item])
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        return;
                    }
                });

                var nodeName = config?.dataNodes[index];
                var filteredList = data?.filter((item) => {
                    if (nodeName == "Type") {
                        return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.toString().toLowerCase().indexOf(value) > -1) : false;
                    }
                    else {
                        return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false;
                    }
                })
                finalResult = filteredList.slice()
            });
        }
        else {
            finalResult = data;
        }
        return finalResult;
    }

    handleDeleteTalent = (id, dataItem) => {
        id = !id ? dataItem?.talent_id : id;
        let currentList = this.state.talent_list?.length > 0 ? [...this.state.talent_list] : [];
        currentList?.map(item => {
            if (item.talent_id === id) {
                item['is_delete'] = 1;
                return item;
            }
        })
        this.setState({ talent_list: currentList, backup_talent_list: currentList, current_list: currentList }, () => {
            this.formatCoveredTalent()
        })
    }

    getRepresentativeDetails = () => {
        // if (this.props?.representativeId) {
        this.setState({ isFetchingRepresentativeDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeatureRepresentativeDetails?id=${this.props?.representativeId}`, this.props.userContext?.active_tenant?.tenant_id, this.state.aesKey)
            .then(response => {
                console.log("response", response)
                if (response?.data?.error || !response?.data) {
                    this.setState({ isFetchingRepresentativeDetails: false })
                }
                else {
                    let representativeDetails = response?.data?.[0];
                    let selectedRep = { value: response?.data?.[0]?.agency_id, text: response?.data?.[0]?.agency_name }
                    // representativeDetails.address_list = initialAddressList
                    // if (representativeDetails.akas.length === 0) {
                    //     let alias = {
                    //         "representative_aliases_id": null,
                    //         "aka": representativeDetails?.representative_name,
                    //         "aka_first_name": representativeDetails?.representative_name,
                    //         "aka_last_name": null,
                    //         "aka_middle_name": null,
                    //         "aka_suffix": null,
                    //         "is_primary": 1,
                    //         "is_delete": 0
                    //     }
                    //     representativeDetails.akas.push(alias);
                    // }
                    let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));
                    let socialInitObject = JSON.parse(JSON.stringify(initialObjectConfig));
                    let phoneInitObject = JSON.parse(JSON.stringify(initialObjectConfig));
                    // let addressInitObject = JSON.parse(JSON.stringify(initialRepresentativeAddress));
                    if (representativeDetails.email_list?.length > 0) {
                        let formattedObj = representativeDetails?.email_list?.map(i => {
                            return {
                                object_id: i.representative_email_id,
                                object_type: i.email_type,
                                value: i.email,
                                is_primary: i.is_primary,
                                is_delete: 0
                            }
                        })
                        representativeDetails.email_list = [initObjectConfig, ...formattedObj]
                    } else {
                        representativeDetails.email_list.push(initObjectConfig);
                    }
                    if (representativeDetails?.social_list?.length > 0) {
                        let formattedObj = representativeDetails?.social_list?.map(i => {
                            return {
                                object_id: i?.representative_social_media_id,
                                object_type: i?.social_media_type,
                                value: i?.social_media_account,
                                is_primary: i.is_primary,
                                is_delete: 0
                            }
                        })
                        representativeDetails.social_list = [socialInitObject, ...formattedObj]
                    } else {
                        representativeDetails.social_list = [];
                        representativeDetails.social_list.push(socialInitObject);
                    }
                    if (representativeDetails?.phone_list?.length > 0) {
                        let formattedObj = representativeDetails?.phone_list?.map(i => {
                            return {
                                object_id: i?.representative_phone_id,
                                object_type: i?.phone_type,
                                value: i?.phone,
                                is_primary: i.is_primary,
                                is_delete: 0,
                                ext: i?.ext
                            }
                        })
                        representativeDetails.phone_list = [phoneInitObject, ...formattedObj]
                    } else {
                        representativeDetails.phone_list = [];
                        representativeDetails.phone_list.push(phoneInitObject);
                    }
                    let talent_list = response?.data[0]?.talent_list
                    // if (representativeDetails?.address_list?.length > 0) {
                    //     let formattedObj = representativeDetails?.address_list?.map(i => {
                    //         return {
                    //             representative_address_id: i?.representative_address_id,
                    //             address_type: i?.address_type,
                    //             address: i?.address,
                    //             city: i?.city,
                    //             state: i?.state,
                    //             country: i?.country,
                    //             zip: i?.zip,
                    //             is_primary: i?.is_primary,
                    //             is_international: i?.is_international,
                    //         }
                    //     })
                    //     representativeDetails.address_list = [addressInitObject, ...formattedObj]
                    // }
                    // } else {
                    //     representativeDetails.address_list = [];
                    //     representativeDetails.address_list.push(addressInitObject);
                    // }
                    // talentDetails.guardians_list = initialGuardianList
                    // talentDetails.guardians_list = this.formateGuardianList(talentDetails)
                    let akasString = representativeDetails?.akas?.map(i => i?.aka)?.join(",");
                    this.props?.setStateByChild({ akas: akasString });
                    this.setState({ isFetchingRepresentativeDetails: false, representativeDetails: representativeDetails, selectedRep, talent_list: talent_list, backup_talent_list: talent_list, current_list: talent_list, selectedLocationId: representativeDetails?.agency_location_id },
                        () => {
                            this.formatCoveredTalent()
                            this.handleTableColumnClick("Covered Talent")
                            CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails?id=${selectedRep?.value}`, this.props.userContext?.active_tenant?.tenant_id)
                                .then(response => {
                                    if (response.data.error) {
                                        console.log("Agency Data error");
                                    }
                                    else {
                                        let agencyDetails = response?.data?.[0]
                                        let location = agencyDetails?.location_list
                                        let locationOptions = location?.map(i => ({ value: i?.agency_location_id, label: i?.location_name?.toUpperCase() }))


                                        let addressItem = location?.find(item => item?.agency_location_id == this.state.selectedLocationId)
                                        let selectedLocationAddress = [{
                                            location_name: addressItem?.location_name,
                                            address_type: addressItem?.address_type,
                                            address: addressItem?.address,
                                            country: addressItem?.country,
                                            state: addressItem?.state,
                                            zip: addressItem?.zip,
                                            city: addressItem?.city,
                                            agency_id: this.state.location_agency_id,
                                            location_agency_id: addressItem?.agency_location_id
                                        }]



                                        this.setState({
                                            locationOptions: locationOptions, currentLocDetails: location, selectedLocationAddress: selectedLocationAddress[0], location_agency_id: agencyDetails?.agency_id,
                                            primaryLocation: this.getPrimaryLocation()
                                        })
                                    }
                                })
                        });
                }


            },
                (err) => {
                    this.setState({
                        isFetchingRepresentativeDetails: false,
                        // representativeDetails: this.formatRepresentativeDetailsResponse(JSON.parse(JSON.stringify({ ...talentInitialDetails })))
                    })
                    console.log("Error in fetching Representative Details:", err)
                })
        // }
    }


    handleTableColumnClick = (column) => {
        console.log("this.state.sortBy", this.state.sortBy);
        console.log("column", column);
        console.log("sortCount", this.state.sortCount);
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Covered Talent": return this.state.tabValue === 1 ? "covered_talent" : "talent_name";
        }
    }

    // handleTableSort = (colName) => {
    //     let config = repTalentConfig
    //     let list = this.state.talent_list
    //     this.setState({
    //         isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    //     }, () => {
    //         this.setState({
    //             config: updateTableSortConfigObject(config, this.state.isSortByAsc, this.state.sortBy, colName),
    //             sortBy: colName,
    //             representative_list: tableSortList(colName === "Phone" ? "Number" : "Name", this.getSortNode(colName), list, this.state.isSortByAsc)
    //         }, () => { this.formatCoveredTalent() });
    //     })
    // }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
                talent_list_to_display: tableSortList("Name", this.getSortNode(colName), this.state.talent_list_to_display, this.state.isSortByAsc)
            });
        })
    }


    formatRepresentativeDetailsResponse = (response) => {
        response?.social_list.unshift(
            JSON.parse(JSON.stringify({ ...socialList }))
        );
        // response?.assistant_list.unshift(
        //   JSON.parse(JSON.stringify({ ...asistantList }))
        // );
        response?.phone_list.unshift(
            JSON.parse(JSON.stringify({ ...phoneObjData }))
        );
        response?.email_list.unshift(
            JSON.parse(JSON.stringify({ ...emailObjData }))
        );
        return response;
    };

    agencyRepAdd = () => {
        let selectedRep = this.state.backup_talent_options?.find(item => item.value === this.state.selectedTalent?.value)
        let newSelectedrep = { talent_id: selectedRep?.value, talent_name: selectedRep?.text, talent_representative_agency_mtrx_id: null, is_delete: 0 }
        let current_talent_list = [...this.state.talent_list, newSelectedrep]
        this.setState({ talent_list: current_talent_list, backup_talent_list: current_talent_list, current_list: current_talent_list }, () => {
            this.setState({ selectedTalent: null })
            this.formatCoveredTalent()
        })
    }

    formatCoveredTalent = () => {
        let talent_list_to_display = this.state.talent_list?.map(tal => {
            return {
                "is_delete": tal.is_delete,
                "talent_id": tal.talent_id,
                "talent_name": tal.talent_name,
                "talent_representative_agency_mtrx_id": tal.talent_representative_agency_mtrx_id
            }
        })
        this.setState({ talent_list_to_display: handlePaginationList(this.state.page, this.state.rowsPerPage, this.state.current_list) })
    }

    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0;
        }
        this.setState(
            (prevState) => ({
                representativeDetails: {
                    ...prevState.representativeDetails,
                    [field]: newValue,
                },
                postInitiated: false,
            }),
            () => {
                this.props.setModalList(this.state.representativeDetails);
                if (field === 'location') {
                    let currentLocDetails = this.state.currentLocDetails
                    let addressItem = currentLocDetails?.find(item => item?.agency_location_id == value)
                    let agency_address_list = [{
                        location_name: addressItem?.location_name,
                        address_type: addressItem?.address_type,
                        address: addressItem?.address,
                        country: addressItem?.country,
                        state: addressItem?.state,
                        zip: addressItem?.zip,
                        city: addressItem?.city,
                        agency_id: this.state.location_agency_id,
                        location_agency_id: addressItem?.agency_location_id
                    }]
                    this.setState({
                        // primaryLocation:agency_address_list[0],
                        selectedLocationAddress: agency_address_list[0],
                        selectedLocationId: value
                    });


                }
                this.props.fieldChanged(true, "change");
            }
        );
    };

    getAgencyAddressLocation = (id) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails?id=${id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Agency Data error");
                }
                else {
                    let agencyDetails = response?.data?.[0]
                    let location = agencyDetails?.location_list
                    let locationOptions = location?.map(i => ({ value: i?.agency_location_id, label: i?.location_name?.toUpperCase() }))

                    let addressItem = location?.find(item => item?.agency_location_id == this.state.selectedLocationId)
                    let selectedLocationAddress = [{
                        location_name: addressItem?.location_name,
                        address_type: addressItem?.address_type,
                        address: addressItem?.address,
                        country: addressItem?.country,
                        state: addressItem?.state,
                        zip: addressItem?.zip,
                        city: addressItem?.city,
                        agency_id: this.state.location_agency_id,
                        location_agency_id: addressItem?.agency_location_id
                    }]
                    this.setState({ locationOptions, currentLocDetails: location, selectedLocationAddress: selectedLocationAddress[0],loadingLocation:false })
                    let findPrimaryLocation = location?.find(item => item.is_primary === 1)
                    if (findPrimaryLocation) {
                        this.handleGeneralEdit('location', findPrimaryLocation?.agency_location_id)
                        let agency_address_list = [{
                            address_type: findPrimaryLocation?.address_type,
                            address: findPrimaryLocation?.address,
                            country: findPrimaryLocation?.country,
                            state: findPrimaryLocation?.state,
                            zip: findPrimaryLocation?.zip,
                            city: findPrimaryLocation?.city,
                            agency_id: agencyDetails?.agency_id
                        }]
                        // this.handleGeneralEdit('address_list', agency_address_list)
                    } else {
                        this.handleGeneralEdit('location', location?.[0]?.agency_location_id)
                        let default_agency_address_list = [{
                            address_type: location?.[0]?.address_type,
                            address: location?.[0]?.address,
                            country: location?.[0]?.country,
                            state: location?.[0]?.state,
                            zip: location?.[0]?.zip,
                            city: location?.[0]?.city,
                            agency_id: agencyDetails?.agency_id
                        }]
                        //   this.handleGeneralEdit('address_list', default_agency_address_list)
                    }

                    console.log("agencyDetails", agencyDetails)
                }
            },
                (err) => {
                    // this.setState({ isFetchingAgencyDetails: false, agencyDetails: [] });
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    handleListEdit = (field, value, obj, listName, check = '') => {
        let representativeDetails = { ...this.state.representativeDetails };
        let id = listName === "email_list" ? "email_id" : listName === "phone_list" ? "phone_id" : listName === "assistant_list" ? "crew_assistant_id" : "";
        let list = representativeDetails[listName]?.map((item, index) => {
            let currentVal = item;
            if ((item[id] === obj[id])) {
                if (field === "is_primary") {
                    currentVal[field] = value ? 1 : 0;
                } else {
                    currentVal[field] = value;
                }
                return currentVal;
            } return currentVal;
        })
        if (check === 'Check') {
            this.initializeCountry(listName, list);
        } else {
            this.handleGeneralEdit(listName, list);
        }
    }

    // handleListEdit = (field, value, obj, listName, check = '') => {
    //     let representativeDetails = { ...this.state.representativeDetails };
    //     let id = (listName === "email_list") ? "talent_email_id" :
    //         (listName === "phone_list") ? "phone_id" :
    //             (listName === "address_list") ? "talent_address_id" :
    //                 (listName === "akas") ? "aka_id" :
    //                     (listName === "legal_list") ? "talent_legal_id" :
    //                         (listName === "minors_list") ? "minors_id" :
    //                             (listName === "representation_list") ? "talent_representative_agency_mtrx_id" : "";
    //     let list = representativeDetails[listName]?.map(item => {
    //         let currentVal = item;
    //         if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
    //             if (field === "is_primary" || field === "is_international") {
    //                 currentVal[field] = value ? 1 : 0;
    //             } else if (field === "address") {
    //                 currentVal = getAddress(field, currentVal, value, addressConfig);
    //             } else {
    //                 currentVal[field] = value;
    //             }
    //             return currentVal;
    //         } return currentVal;
    //     })
    //     this.setState(prevState => ({
    //         checkedMultiPrimary: {
    //             ...prevState.checkedMultiPrimary,
    //             [listName]: this.checkMultiplePrimary(list)
    //         }
    //     }))
    //     if (check === 'Check') {
    //         this.initializeCountry(listName, list);
    //     } else {
    //         this.handleGeneralEdit(listName, list);
    //     }
    // }

    initializeCountry = (field, value) => {
        this.setState(prevState => ({
            representativeDetails: {
                ...prevState.representativeDetails,
                [field]: value
            },
            postInitiated: false
        }), () => {
            this.props.setModalList(this.state.representativeDetails);
        })
    }

    handleRepListChange = (list, obj, listName) => {
        let talentDetails = { ...this.state.talentDetails };
        let id = "talent_representative_agency_mtrx_id";
        let newList = []
        if (listName === "agency") {
            newList = talentDetails.representation_list?.map(item => {
                if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                    item.agency_id = list?.value || null;
                    item.phone = list?.phone || null;
                    item.agency_email = list?.email || null;
                    item.agency_email_id = list?.emailType || null;
                    item.agency_phone_id = list?.phoneType || null;
                    item.agency_name = list?.text || null;
                    if (!list) {
                        item.address_1 = null;
                        item.address_2 = null;
                        item.address_3 = null;
                        item.city = null;
                        item.country = null;
                        item.country_id = null;
                        item.representative_address_id = null;
                        item.representative_email = null;
                        item.representative_email_id = null;
                        item.representative_id = null;
                        item.representative_name = null;
                        item.representative_phone = null;
                        item.representative_phone_id = null;
                        item.representative_type = null;
                        item.representative_type_id = null;
                        item.state = null;
                        item.state_id = null;
                        item.zip = null;
                        item.is_primary = list?.is_primary || 0;
                    }
                    return item;
                } return item;
            });
        } else if (listName === "representative") {
            newList = talentDetails.representation_list?.map(item => {
                if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                    item.address_1 = list?.address_1 || null;
                    item.address_2 = list?.address_2 || null;
                    item.address_3 = list?.address_3 || null;
                    item.city = list?.city || null;
                    item.country = list?.country || null;
                    item.country_id = list?.country_id || null;
                    item.representative_address_id = list?.address_id || null;
                    item.representative_email = list?.email || null;
                    item.representative_email_id = list?.email_id || null;
                    item.representative_id = list?.representative_id || null;
                    item.representative_name = list?.representative_name || null;
                    item.representative_phone = list?.phone || null;
                    item.representative_phone_id = list?.phone_id || null;
                    item.representative_type = list?.type_name || null;
                    item.representative_type_id = list?.type_id || null;
                    item.state = list?.state || null;
                    item.state_id = list?.state_id || null;
                    item.zip = list?.zip || null;
                    item.is_primary = list?.is_primary || 0;
                    return item;
                } return item;
            });
        }
        this.handleGeneralEdit("representation_list", newList);
    }

    checkMultiplePrimary = (list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }

    // formatAgencyDetailsResponse = (response) => {
    //     //if (response?.email_list?.length === 0) {
    //     response?.email_list.push(JSON.parse(JSON.stringify({ ...talentEmail })));
    //     //}
    //     //if (response?.phone_list?.length === 0) {
    //     response?.phone_list.push(JSON.parse(JSON.stringify({ ...talentPhone })));
    //     //}
    //     //if (response?.address_list?.length === 0) {
    //     response?.address_list.push(JSON.parse(JSON.stringify({ ...talentAddress })));
    //     // }
    //     if (response?.akas?.length === 0) {
    //         response?.akas.push(JSON.parse(JSON.stringify({ ...talentAka })));

    //     }
    //     if (response?.legal_list?.length === 0) {
    //         response?.legal_list.push(JSON.parse(JSON.stringify({ ...talentLegal })));

    //     }
    //     if (response?.minors_list?.length === 0) {
    //         response?.minors_list.push(JSON.parse(JSON.stringify({ ...talentMinor })));

    //     }
    //     return response;
    // }

    addAdditionalField = (listName) => {
        let representativeDetails = { ...this.state.representativeDetails };
        switch (listName) {
            case 'email_list':
                let initialEmailArray = { ...talentEmail };
                let email_list = [...talentDetails.email_list];
                initialEmailArray.index = this.state.emailIndex;
                initialEmailArray.canRemove = true;
                email_list.push(initialEmailArray)
                talentDetails.email_list = email_list;
                this.setState({ emailIndex: this.state.emailIndex + 1, talentDetails: talentDetails });
                break;
            case 'phone_list':
                let initialPhoneArray = { ...phoneObjData };
                let phone_list = [...representativeDetails.phone_list];
                initialPhoneArray.index = this.state.phoneIndex;
                initialPhoneArray.canRemove = true;
                phone_list.push(initialPhoneArray)
                representativeDetails.phone_list = phone_list;
                this.setState({ phoneIndex: this.state.phoneIndex + 1, representativeDetails: representativeDetails });
                break;
            case 'address_list':
                let initialAddressArray = { ...talentAddress };
                let address_list = [...talentDetails.address_list];
                initialAddressArray.index = this.state.addressIndex;
                initialAddressArray.canRemove = true;
                address_list.push(initialAddressArray)
                talentDetails.address_list = address_list;
                this.setState({ addressIndex: this.state.addressIndex + 1, talentDetails: talentDetails });
                break;
            case 'akas':
                let initialAkaArray = { ...talentAka };
                let akas = [...talentDetails.akas];
                initialAkaArray.index = this.state.akaIndex;
                initialAkaArray.canRemove = true;
                akas.push(initialAkaArray)
                talentDetails.akas = akas;
                this.setState({ akaIndex: this.state.akaIndex + 1, talentDetails: talentDetails });
                break;
            case 'legal_list':
                let initialLegalArray = { ...talentLegal };
                let legal_list = [...talentDetails.legal_list];
                initialLegalArray.index = this.state.loanoutIndex;
                initialLegalArray.canRemove = true;
                legal_list.push(initialLegalArray)
                talentDetails.legal_list = legal_list;
                this.setState({ loanoutIndex: this.state.loanoutIndex + 1, talentDetails: talentDetails });
                break;
            case 'minors_list':
                let initialMinorArray = { ...talentMinor };
                let minors_list = [...talentDetails.minors_list];
                initialMinorArray.index = this.state.minorIndex;
                initialMinorArray.canRemove = true;
                minors_list.push(initialMinorArray)
                talentDetails.minors_list = minors_list;
                this.setState({ minorIndex: this.state.minorIndex + 1, talentDetails: talentDetails });
                break;
        }
    }

    removeField = (listName, callbackItem) => {
        let talentDetails = { ...this.state.talentDetails };
        if (!callbackItem.canRemove) {
            let index = talentDetails[listName].indexOf(callbackItem);
            if (index > -1) {
                talentDetails[listName].splice(index, 1);
            }
        } else {
            let list = talentDetails[listName]?.filter(item => item.index !== callbackItem.index);
            talentDetails[listName] = list;
        }
        this.setState({ talentDetails: talentDetails });
    }

    addRepresentationList = () => {
        let talentDetails = { ...this.state.talentDetails };
        let initialRepList = { ...talentRepresentation };
        let representation_list = [...talentDetails.representation_list];
        initialRepList.index = this.state.repIndex;
        initialRepList.canRemove = true;
        representation_list.push(initialRepList)
        talentDetails.representation_list = representation_list;
        this.setState({ repIndex: this.state.repIndex + 1, talentDetails: talentDetails });
    }
    handleSelectedRep = (newValue) => {
        console.log("mewValue", newValue)
        if (newValue) {
            console.log("in if")
            if (newValue?.optionType === "new") {
                this.setState({showAgencyPopup:true, showNewAgencyPopup: { agency_id: newValue?.value, agency_name: newValue?.value },locationOptions:[] })
            }
            //let selectedRepData = this.state.representative_options?.find(item => item.value === newValue.value);
            this.setState({ selectedRep: newValue,loadingLocation:true }, () => {
                this.getAgencyAddressLocation(newValue?.value)
                this.handleGeneralEdit('agency_id', newValue?.value)
                this.handleGeneralEdit('agency_name', newValue?.text)
            })
        }
        else {
            console.log("in else")
            this.setState({ selectedRep: null,loadingLocation:false,locationOptions:[] })
        }
    }

    handleSelectedTalent = (newValue) => {
        console.log("newValue", newValue)
        if (newValue) {
            let selectedTalentData = this.state.talent_options?.find(item => item.value === newValue.value);
            this.setState({ selectedTalent: newValue, selectedTalentData: selectedTalentData })
        }
        else {
            this.setState({ selectedTalent: null })
        }
    }



    onAliasSave = (value) => {
        let representativeDetails = { ...this.state.representativeDetails }
        representativeDetails.akas.push(value);
        this.setState({ representativeDetails: representativeDetails })
    }

    addRemoveObject(list, value) {
        let objectList = [...this.state?.representativeDetails?.[list]];
        objectList = objectList?.filter((i, index) => index != 0);
        let initObjectConfig = JSON.parse(JSON.stringify(initialObjectConfig));

        value.type = 'new'
        value.object_id = Date.now();
        let newObjectList = objectList.filter(item => item.is_delete === 0)
        if (newObjectList?.length === 0) {
            value["is_primary"] = 1
        }
        objectList = [initObjectConfig, ...objectList, value]

        this.handleGeneralEdit(list, objectList)
    }
    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.representativeDetails?.[list]];
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        if (key == 'is_primary') {
            objectList?.map(i => i.is_primary = 0);
            objectList[objectIndex][key] = 1;
        } else if (key == 'is_delete') {
            objectList[objectIndex][key] = 1;
        } else {
            objectList[objectIndex][key] = value;
        }
        this.handleGeneralEdit(list, objectList)
    }

    // onChangeObject(list,id,key,value){
    //     let objectList = [...this.state?.representativeDetails?.[list]];
    //     let objectIndex = id ? objectList?.findIndex(i=>i.object_id == id) : 0;
    //     objectList[objectIndex][key]= value;
    //     this.handleGeneralEdit(list,objectList)
    //  }  

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 0: return <RepresentativePersonal
                isEditing={this.props?.isEditing}
                representativeDetails={this.state.representativeDetails}
                postInitiated={this.state.postInitiated}
                checkMultiplePrimary={this.checkMultiplePrimary}
                addCallback={this.addAdditionalField}
                addRemoveObject={this.addRemoveObject.bind(this)}
                onChangeObject={this.onChangeObject.bind(this)}
                removeCallback={this.removeField}
                handleListEdit={this.handleListEdit}
                onAddressChange={this.onAddressChange.bind(this)}
                // guardianChange={this.guardianChange.bind(this)}
                isLoading={this.state.isFetchingRepresentativeDetails}
                getAgencyList={this.getAgencyList}
                company_options={this.state.company_options}
                locationOptions={this.state.locationOptions}
                primaryLocation={this.state.primaryLocation}
                selectedLocationId={this.state.selectedLocationId}
                selectedLocationAddress={this.state.selectedLocationAddress}
                onAliasSave={this.onAliasSave.bind(this)}
                loadingLocation={this.state.loadingLocation}
                getAge={this.getAge}
                setMinor={this.setMinor}
                initialAgeValue={this.state.initialAgeValue}
                handleSelectedRep={this.handleSelectedRep.bind(this)}
                selectedRep={this.state.selectedRep}
                handleGeneralEdit={this.handleGeneralEdit}
                navFuns={this.props?.navFuns}
                getRepresentativeDetails={this.getRepresentativeDetails.bind(this)}
                handleHideRepoPopup={this.props.handleHideRepoPopup}
                />;
            case 1: return <RepresentativeTalent
                isFetchingAgencyDetails={this.state.isFetchingAgencyDetails}
                talent_list_to_display={this.state.talent_list_to_display}
                isEditing={this.props?.isEditing}
                config={this.state?.config}
                handleTableColumnClick={this.handleTableColumnClick.bind(this)}
                filterList={this.state.filterList}
                onChangeHandler={this.onChangeHandler}
                addFilterChangeHandler={this.addFilterChangeHandler}
                handleDeleteTalent={this.handleDeleteTalent}
                talent_options={this.state.talent_options}
                getTalentList={this.getTalentList}
                handleSelectedTalent={this.handleSelectedTalent}
                selectedTalent={this.state.selectedTalent}
                agencyRepAdd={this.agencyRepAdd}
                backup_talent_list={this.state.backup_talent_list}
                current_list={this.state.current_list}
                navFuns={this.props.navFuns}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
            />;
        }
    }

    postRepresentativeDetails = (representativeDetails) => {
        this.props.setPostFlag(true);
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureRepresentativeDetails', representativeDetails, this.props.userContext?.active_tenant?.tenant_id, 1)
            .then((response) => {
                if (response.data.error) {
                    //Add your error message to alert box from response.data.error
                    this.props.fieldChanged(true, "fail");
                    this.props.setPostFlag(false);
                } else {
                    if (this.props.isProfileChanged) {
                        this.props.uploadProfile(response.data[0].representative_id);
                    }
                    this.props.fieldChanged(false, "error");
                    this.props.fieldChanged(true, "success");
                    this.props.setPostFlag(false);
                    // if (this.props?.handleTalentCreation) {
                    //     let newTalent = {
                    //         'id': response?.data[0]?.talent_id
                    //     }
                    //     this.props?.handleTalentCreation(newTalent)
                    // }
                }
            }, (err) => {
                this.props.fieldChanged(true, "fail");
                this.props.setPostFlag(false);
                console.log("Post talent details error: " + err);
            });
    }

    checkIfPrimaryIsChecked = (representativeDetails) => {
        if (representativeDetails.email_list.length !== 0 && !representativeDetails.email_list?.some(item => item.is_primary == 1)) {
            representativeDetails.email_list[0].is_primary = 1;
        }
        if (representativeDetails.phone_list.length !== 0 && !representativeDetails.phone_list?.some(item => item.is_primary == 1)) {
            representativeDetails.phone_list[0].is_primary = 1;
        }
        if (representativeDetails.address_list.length !== 0 && !representativeDetails.address_list?.some(item => item.is_primary == 1)) {
            representativeDetails.address_list[0].is_primary = 1;
        }
        if (representativeDetails.social_list.length !== 0 && !representativeDetails.social_list?.some(item => item.is_primary == 1)) {
            representativeDetails.social_list[0].is_primary = 1;
        }
        // if (representativeDetails.minors_list.length !== 0 && !representativeDetails.minors_list?.some(item => item.is_primary == 1)) {
        //     representativeDetails.minors_list[0].is_primary = 1;
        // }
        // if (representativeDetails.representation_list.length !== 0 && !representativeDetails.representation_list?.some(item => item.is_primary == 1)) {
        //     representativeDetails.representation_list[0].is_primary = 1;
        // }
        return representativeDetails;
    }

    setRepresentativeUniqueEntries = () => {
        let representativeDetails = { ...this.state.representativeDetails };
        representativeDetails.email_list = [...representativeDetails.email_list].filter((tag, index, array) =>
            (array.findIndex(t => t.email == tag.email && t.email_type == tag.email_type) == index) &&
            tag.email && tag.email_type);
        representativeDetails.phone_list = [...representativeDetails.phone_list].filter((tag, index, array) =>
            (array.findIndex(t => t.phone == tag.phone && t.phone_type == tag.phone_type) == index) &&
            tag.phone && tag.phone_type);
        representativeDetails.address_list = [...representativeDetails.address_list].filter((tag, index, array) =>
            (array.findIndex(t => t.address == tag.address && t.address_type == tag.address_type) == index) && (tag.address || tag.city || tag.state || tag.zip));
        representativeDetails.social_list = [...representativeDetails.social_list].filter((tag, index, array) =>
            (array.findIndex(t => t.social_media_type == tag.social_media_type && t.social_media_account == tag.social_media_account) == index));
        // talentDetails.minors_list = [...talentDetails.minors_list].filter((tag, index, array) =>
        //     (array.findIndex(t => t.guardian_type_id == tag.guardian_type_id && t.guardian_name == tag.guardian_name &&
        //         t.phone == tag.phone && t.email == tag.email) == index)
        //     && (talentDetails?.minor === 0 || !talentDetails?.minor ? (tag.guardian_type_id || tag.guardian_name || tag.phone || tag.email) : true));
        // talentDetails.akas = [...talentDetails.akas].filter((tag, index, array) =>
        //     (array.findIndex(t => t.aka == tag.aka) == index) && tag.aka);
        return representativeDetails;
    }

    onAddressChange(id, key, value) {
        let addressList = this.state?.representativeDetails?.address_list?.length > 0 ? JSON.parse(JSON.stringify(this.state?.representativeDetails?.address_list)) : [];
        let addressIndex = addressList?.findIndex(i => i.representative_address_id == id);
        if (key == 'is_primary') {
            addressList?.map(i => i.is_primary = 0);
            addressList[addressIndex][key] = 1;
        } else if (key == 'is_delete') {
            addressList[addressIndex][key] = 1;
        } else if (id) {
            addressList[addressIndex] = value;
        } else {
            value.representative_address_id = Date.now();
            value.type = "new"
            let newAddressList = addressList.filter(item => !item.is_delete)
            if (newAddressList?.length === 0) {
                value["is_primary"] = 1
            } else {
                if (value["is_primary"] === 1) {
                    addressList?.map(i => i.is_primary = 0);
                }
            }
            addressList?.push(value);
        }
        this.handleGeneralEdit('address_list', addressList);
    }

    handleRepresentativeSubmit = () => {
        this.setState({ postingAgency: true })
        let representativeDetails = this.state.representativeDetails
        representativeDetails.email_list = representativeDetails?.email_list?.filter(item => item.value !== null)
        representativeDetails.email_list = representativeDetails?.email_list?.map(item => {
            return {
                email_id: item.type === 'new' ? null : item.object_id,
                email_type: item.object_type,
                email: item.value,
                is_primary: item.is_primary,
                is_delete: item.is_delete
            }
        })
        representativeDetails.phone_list = representativeDetails?.phone_list?.filter(item => item.value !== null)
        representativeDetails.phone_list = representativeDetails?.phone_list?.map(item => {
            return {
                phone_id: item.type === 'new' ? null : item.object_id,
                phone_type: item.object_type,
                phone: item.value,
                is_primary: item.is_primary,
                is_delete: item.is_delete,
                ext: item.ext
            }
        })
        representativeDetails.social_list = representativeDetails?.social_list?.filter(item => item.value !== null)
        representativeDetails.social_list = representativeDetails?.social_list?.map(item => {
            return {
                representative_social_media_id: item.type === 'new' ? null : item.object_id,
                social_media_type: item.object_type,
                social_media_account: item.value,
                is_primary: item.is_primary,
                is_delete: item.is_delete,
            }
        })
        representativeDetails.address_list = representativeDetails?.address_list?.map(item => {
            return {
                representative_address_id: item.representative_address_id ? item.representative_address_id : null,
                address_type: item.address_type,
                address: item.address,
                city: item.city,
                zip: item.zip,
                state: item.state,
                country: item.country,
                is_primary: item.is_primary ? 1 : 0,
                is_delete: item.is_delete ? item.is_delete : 0,
                location: this.state.selectedLocationId
            }
        })
        representativeDetails.location = this.state.selectedLocationId
        representativeDetails.talent_list = this.state.backup_talent_list
        // let representativeDetailsPostJson = this.checkIfPrimaryIsChecked(this.setRepresentativeUniqueEntries());
        this.setState({ postInitiated: true }, () => {
            if (this.validateErrorFlags(representativeDetails)) {
                //this.editPhoneNumbers(talentDetailsPostJson);
                this.postRepresentativeDetails(representativeDetails)
            } else {
                console.log("Submit Fail")
            }
        })
    }
    setNewAgency(agencyObj,agencyIdNew=null) {
        console.log("agencyObj", agencyObj);
        console.log("selectedRep", this.state.selectedRep);
        console.log("representativeDetails", this.state.representativeDetails);
        this.setState(
            (prevState) => ({
                selectedRep: {
                    ...prevState.selectedRep,
                    ["value"]: agencyIdNew||null,
                    ["text"]: agencyObj?.text ? agencyObj?.text : agencyObj?.agency_name ? agencyObj?.agency_name:null,
                },
                postInitiated: false,
                loadingLocation:true,
            }), () => this.getAgencyAddressLocation(agencyIdNew?agencyIdNew:null))
        this.setState(
            (prevState) => ({
                representativeDetails: {
                    ...prevState.representativeDetails,
                    ["agency_id"]: agencyIdNew||null,
                    ["agency_name"]: agencyObj?.text ? agencyObj?.text : agencyObj?.agency_name ? agencyObj?.agency_name:null,

                },
                postInitiated: false,
                loadingLocation:true,
            }), () => this.getAgencyAddressLocation(agencyIdNew?agencyIdNew:null))

    }
    handleCloseAgencyModal=()=>{
        this.setState({ showAgencyPopup: null },()=>{
            //this.getRepresentativeDetails();
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props?.isEditing !== prevProps?.isEditing){
            this.getRepresentativeDetails();
        }
    }
    
    render() {
        return (
            <div className="RepresentativeContainer">
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleRepresentativeSubmit()}></input>

                {this.getTabComponent()}
                
                {/* {this.state.openAgencyPopOver && <NewAgency
                    id={'newAgencyFromadvancesea'}
                    open={this.state.openAgencyPopOver}
                    setPopOver={this.state.openAgencyPopOver}
                    handleClose={() => this.setState({ openAgencyPopOver: null })}
                    handleCloseAgencyPopOver={() => this.setState({ openAgencyPopOver: null })}
                    agencySelected={this.state?.selectedRep}
                    setObjectSelected={(type, value) => { this.setState({ showAgencyPopup: { agency_id: value?.value, agency_name: value?.text } }) }}
                    setNewObjectSelected={(type, value) => { this.setState({ showAgencyPopup: null, openAgencyPopOver: null }) }}
                    setNewAgency={this.setNewAgency.bind(this)}
                />} */}
                {
                    this.state.showAgencyPopup &&
                    <ModalComponent
                        id={this.state.showNewAgencyPopup?.agency_id}
                        agencyId={{ value: this.state.showNewAgencyPopup?.agency_id }}
                        open={this.state.showNewAgencyPopup || false}
                        handleClose={this.handleCloseAgencyModal.bind(this)}
                        tabList={agencyNavList}
                        headerName={this.state?.showNewAgencyPopup?.agency_name}
                        headerText={"Agency"}
                        mode="new"
                        isProfileDiv={true}
                        addButtonText={"New Agency"}
                        objectType={"AGENCY"}
                        akas={this.state.showNewAgencyPopup?.aka || null}
                        navFuns={this.functions}
                        dataUrl='casterFeatureAgencyDetails'
                        setNewAgency={this.setNewAgency.bind(this)} />
                }
                {/* {
                    this.state.showAgencyPopup &&
                    <ModalComponent
                        id={this.state.showAgencyPopup?.agency_id}
                        agencyId={{ value: this.state.showAgencyPopup?.agency_id }}
                        open={this.state.showAgencyPopup || false}
                        handleClose={this.handleCloseAgencyModal.bind(this)}
                        tabList={agencyNavList}
                        headerName={this.state?.showAgencyPopup?.agency_name}
                        headerText={"Agency"}
                        mode="edit"
                        isProfileDiv={true}
                        addButtonText={"New Agency"}
                        objectType={"AGENCY"}
                        akas={this.state.showAgencyPopup?.aka || null}
                        navFuns={this.functions}
                        dataUrl='casterFeatureAgencyDetails'
                        setNewAgency={this.setNewAgency.bind(this)} />
                } */}
            </div>
        );
    }
}
export default withUserContext(RepresentativeContainer);