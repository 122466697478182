import React, { Component } from 'react';
import { withUserContext } from "../../../contexts/UserContext";
import AgentDetails from './AgentDetails';
import AgencyRepresentatives from './AgencyRepresentatives';
import AgencyTalent from './AgencyTalent';
import CasterService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import { initialAgencySocial, agencyRepsConfig, locationConfig, repsFilterConfig, talentsFilterConfig, agencyTalentsConfig, initialPhoneObject, newAgencyDetailsObj } from './Config';
import { handlePaginationList, updateTableSortConfigObject, tableSortList, applyFilterTable } from '../../Common/TableHelper';


let location = locationConfig
class AgentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            isFetchingAgencyDetails: false,
            agencyDetails: {},
            isFetchingLookupData: false,
            socialOptions: [],
            isEditing: this.props?.isEditing,
            typeOptions: [],
            agencyLocationList: [],
            existingSocialList: [],
            deletedSocial: [],
            representative_list: [],
            config: JSON.parse(JSON.stringify({ ...agencyRepsConfig })),
            agencyTalentsConfig: JSON.parse(JSON.stringify({ ...agencyTalentsConfig })),
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            filterList: JSON.parse(JSON.stringify({ ...repsFilterConfig })),
            backup_representative_list: [],
            filterInputs: [],
            representative_options: [],
            selectedRep: null,
            selectedRepData: null,
            backup_rep_options: [],
            rep_list_to_display: [],
            postingAgency: false,
            fetchingAgencyTalents: false,
            agency_talent_list: [],
            talentsFilterList: JSON.parse(JSON.stringify({ ...talentsFilterConfig })),
            backup_agency_talent_list: [],
            page: 0,
            rowsPerPage: 10,
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            selectedLocation: null,
            added_location_list: [],
            openLocationPopOver: false,
            postInitiated: false,
            initialLocationConfig: locationConfig
        }
    }
    componentDidMount() {
        if (this.props?.mode === "new") {
            let newAgencyDetails = JSON.parse(JSON.stringify(newAgencyDetailsObj));
            if(this.props?.headerName){
                newAgencyDetails.agency_name=this.props?.headerName;
            }
            newAgencyDetails?.social_list?.push(JSON.parse(JSON.stringify(initialAgencySocial)));
            this.setState({agencyDetails:newAgencyDetails})
        } else if (this.props.mode === "edit") {
            this.getAgencyDetails(this.props.agencyId?.value);
            this.getAgencyTalents(this.props?.agencyId?.value)
            // this.getAgencyDetails(44);
            // this.getAgencyDetails(1672);
        }
        this.getStaticData("Social", "socialOptions");
        this.getAgencyOccupation();
        let dummyConfig = this.state.config
        this.props?.isEditing ? dummyConfig.actions = ["minus-circle"] : dummyConfig.actions = []
        this.setState({ config: dummyConfig })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps?.isEditing != this.props?.isEditing) {
            let dummyConfig = this.state.config
            this.props?.isEditing ? dummyConfig.actions = ["minus-circle"] : dummyConfig.actions = []
            this.setState({ config: dummyConfig });
        }
    }


    static getDerivedStateFromProps(props, state) {
        if (props.tabValue !== state.tabValue) {
            return {
                tabValue: props.tabValue
            }
        }
        if (props?.isEditing !== state.isEditing) {
            return {
                isEditing: props.isEditing
            }
        }
        return null
    }

    formatAgencyDetails = (response) => {
        if (response?.social_list?.length === 0) {
            response?.social_list.push(JSON.parse(JSON.stringify({ ...initialAgencySocial })));
        }
        response?.location_list?.map((item, index) => {
            if (item?.phone_list?.length === 0) {
                item?.phone_list.push(JSON.parse(JSON.stringify({ ...initialPhoneObject })));
            }
        })
        return response;
    }

    getAgencyOccupation = () => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=agency_occupation`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.lookup_value, label: i?.lookup_value, id: i?.lookup_data_id || null }))
                    this.setState({ typeOptions: formattedList })
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 0: return <AgentDetails
                isEditing={this.state?.isEditing}
                socialOptions={this.state.socialOptions}
                typeOptions={this.state.typeOptions}
                agencyDetails={this.state.agencyDetails}
                agencyLocationList={this.state.agencyLocationList}
                // addSocial={this.addSocial}
                // removeSocial={() => this.removeSocial()}
                isFetchingAgencyDetails={this.state.isFetchingAgencyDetails}
                onAgencyDetailChange={this.onAgencyDetailChange.bind(this)}
                addRemoveObject={this.addRemoveObject.bind(this)}
                onChangeObject={this.onChangeObject.bind(this)}
                onAliasSave={this.onAliasSave.bind(this)}
                locationConfig={location}
                onLocationSave={this.onLocationSave}
                editLocation={this.editLocation.bind(this)}
                selectedLocation={this.state.locationDets}
                handleShareClick={this.handleShareClick.bind(this)}
                handleMarkAsPrimary={this.handleMarkAsPrimary.bind(this)}
                openLocationdialogue={this.state.openLocationPopOver}
                openLocationPopOver={() => this.openLocationPopOver()}
                closeLocationPopOver={() => this.closeLocationPopOver()}
                deleteLocation={this.deleteLocation.bind(this)}
                postInitiated={this.state.postInitiated}
                navFuns={this.props.navFuns}
            />;
            case 1: return <AgencyRepresentatives
                isFetchingAgencyDetails={this.state.isFetchingAgencyDetails}
                rep_list_to_display={this.state.rep_list_to_display}
                isEditing={this.state?.isEditing}
                config={this.state?.config}
                handleTableColumnClick={this.handleTableColumnClick.bind(this)}
                filterList={this.state.filterList}
                onChangeHandler={this.onChangeHandler}
                addFilterChangeHandler={this.addFilterChangeHandler}
                handleDeleteRep={this.handleDeleteRep}
                representative_options={this.state.representative_options}
                getRepresentativesList={this.getRepresentativesList}
                handleSelectedRep={this.handleSelectedRep}
                selectedRep={this.state.selectedRep}
                agencyRepAdd={this.agencyRepAdd}
                navFuns={this.props.navFuns}
            />;
            case 2: return <AgencyTalent
                agencyTalentsConfig={this.state.agencyTalentsConfig}
                agency_talent_list={this.state.agency_talent_list}
                talentsFilterList={this.state.talentsFilterList}
                typeOptions={this.state.typeOptions}
                onChangeHandler={this.onChangeHandler}
                addFilterChangeHandler={this.addFilterChangeHandler}
                handleTableColumnClick={this.handleTableColumnClick.bind(this)}
                page={this.state.page}
                rowsPerPage={this.state.rowsPerPage}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                backup_agency_talent_list={this.state.backup_agency_talent_list}
                navFuns={this.props.navFuns}
            />
        }
    }

    addRemoveObject(list, value) {
        let objectList = [...this.state?.agencyDetails?.[list]];
        objectList = objectList?.filter((i, index) => index != 0);
        let initObjectConfig = JSON.parse(JSON.stringify(initialAgencySocial));
        value.type = 'new'
        value.object_id = new Date();
        let newObjectList = objectList.filter(item => item.is_delete === 0)
        if (newObjectList?.length === 0) {
            value["is_primary"] = 1
        }
        objectList = [initObjectConfig, ...objectList, value]

        this.handleGeneralEdit(list, objectList)
    }
    handleGeneralEdit = (field, value) => {
        let newValue = value;
        if (typeof value === "boolean") {
            newValue = value ? 1 : 0;
        }
        this.setState(
            (prevState) => ({
                agencyDetails: {
                    ...prevState.agencyDetails,
                    [field]: newValue,
                },
                postInitiated: false,
            }),
            () => {
                this.props.setModalList(this.state.agencyDetails);
                this.props.fieldChanged(true, "change");
            }
        );
    };

    onChangeObject(list, id, key, value) {
        let objectList = [...this.state?.agencyDetails?.[list]];
        let objectIndex = id ? objectList?.findIndex(i => i.object_id == id) : 0;
        objectList[objectIndex][key] = value;
        this.handleGeneralEdit(list, objectList)
    }
    formatPhoneNumber = (phone_list) => {
        phone_list = phone_list?.map(i => {
            return {
                object_id: i?.agency_location_phone_id,
                object_type: i?.phone_type,
                value: i?.phone,
                ext: i?.ext,
                is_delete: i?.is_delete,
                is_primary: i?.is_primary
            }
        })
        return phone_list
    }

    getAgencyDetails = (agency_id) => {
        this.setState({ isFetchingAgencyDetails: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails?id=${agency_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Agency Data error");
                }
                else {
                    let agencyDetails = response?.data?.[0]
                    let initObjectConfig = JSON.parse(JSON.stringify(initialAgencySocial));
                    let socialInitObject = JSON.parse(JSON.stringify(initialAgencySocial));
                    if (response?.data[0]?.social_list?.length > 0) {
                        let formattedObj = response?.data[0]?.social_list?.map(i => {
                            return {
                                object_id: i?.agency_social_media_id,
                                object_type: i?.social_media_type,
                                value: i?.social_media_account,
                                is_primary: i.is_primary,
                                is_delete: i.is_delete
                            }
                        })
                        agencyDetails.social_list = [socialInitObject, ...formattedObj]
                    } else {
                        agencyDetails.social_list = [];
                        agencyDetails.social_list.push(socialInitObject);
                    }
                    let existingSocialList = response?.data[0]?.social_list
                    let representative_list = response?.data[0]?.representative_list
                    if (agencyDetails?.location_list?.length > 0) {
                        let formattedObj = agencyDetails?.location_list?.map(item => {
                            let phone_list = this.formatPhoneNumber(item?.phone_list)
                            let curr_state = item?.state
                            if (curr_state?.includes('-')) {
                                let splitArray = curr_state?.split("-")
                                curr_state = splitArray[0]
                              }
                            return {
                                "type": 'edit',
                                "agency_location_id": item?.agency_location_id,
                                "location_name": item?.location_name || "--",
                                "phone_list": phone_list,
                                "phone": item?.phone_list?.filter(i => i?.is_delete == 0)?.map((phone, index) => `${phone["phone_type"] ? phone["phone_type"] : ''}${phone["phone"] ? ' ' + phone["phone"] : ' '}`),
                                "address": `${item["address"] ? item["address"] : ''}${item["city"] ? '\n' + item["city"] + ', ' : '\n' + ''}${curr_state ? '' + curr_state + ' ' : ''}${item["zip"] ? item["zip"] : ''}${item["country"] && (item["country"] !== "United States" && item["country"] !== "USA" && item["country"] !== "US") ? (item["city"] || curr_state || item["zip"]) ? '\n' + item["country"] : item["country"] : ''}`,
                                "actual_address": item?.address,
                                "city": item?.city,
                                "state": item?.state,
                                "country": item?.country,
                                "zip": item?.zip,
                                "is_primary": item.is_primary,
                                "is_delete": item.is_delete
                            }
                        })
                        agencyDetails.location_list = formattedObj
                        // if(agencyDetails.akas.length === 0) {
                        //     let alias = {
                        //         "agency_aliases_id": null,
                        //         "alias": agencyDetails.agency_name,
                        //         "is_primary": 1,
                        //         "is_delete": 0
                        //     }
                        //     agencyDetails.akas.push(alias);
                        // }
                    }
                    let rep_list_to_display = agencyDetails?.representative_list?.map(rep => {
                        return {
                            "representative_id": rep.representative_id,
                            "representative_name": rep.representative_name,
                            "talent_id": rep.talent_list?.map(talent => talent.talent_id),
                            "covered_talent": rep.talent_list?.map(talent => talent.talent_name).join(' | '),
                            "is_delete": rep.is_delete,
                            "type": 'edit',
                            "talent_representative_agency_mtrx_id": rep?.talent_representative_agency_mtrx_id
                        }
                    })
                    let wholeAkaString = agencyDetails?.akas?.map(i => i?.alias)?.join(',');
                    this.props?.setStateByChild({ akas: wholeAkaString })
                    this.setState({ agencyDetails: agencyDetails, agencyLocationList: agencyDetails?.location_list, added_location_list: response.data[0].location_list, existingSocialList: existingSocialList, isFetchingAgencyDetails: false, representative_list: representative_list, backup_representative_list: rep_list_to_display },
                        () => {
                            this.formatCoveredTalent()
                            this.handleTableColumnClick("Representative Name")
                        });
                }
            },
                (err) => {
                    this.setState({ isFetchingAgencyDetails: false, agencyDetails: [] });
                    console.log("Error in fetching Agency Details:", err)
                })
    }

    getStaticData = (object_type, list_variable) => {
        this.setState({ isFetchingLookupData: true });
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${object_type}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Error fetching Look up data");
                }
                else {
                    let formattedList = response.data.map((item) => ({
                        value: item.lookup_value,
                        label: item.lookup_value
                    }))
                    this.setState({ [list_variable]: formattedList })
                }
            },
                (err) => {
                    this.setState({ isFetchingLookupData: false });
                    console.log("Error in fetching Lookup Details:", err)
                })
    }

    onAliasSave = (value) => {
        let agencyDetails = { ...this.state.agencyDetails }
        agencyDetails.akas.push(value);
        this.setState({ agencyDetails: agencyDetails }, () => {
            this.props.setModalList(this.state.agencyDetails);
        });
    }

    onAgencyDetailChange = (field, value, index) => {
        let agencyDetails = { ...this.state.agencyDetails }
        this.setState((prevState) => ({
            agencyDetails: {
                ...prevState?.agencyDetails,
                [field]: value
            }
        }), () => {
            this.props.setModalList(this.state.agencyDetails);
            this.props.fieldChanged(true, "change");
        })
    }

    handleTableColumnClick = (column) => {
        console.log("this.state.sortBy", this.state.sortBy);
        console.log("column", column);
        console.log("sortCount", this.state.sortCount);
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        let config = this.state.tabValue === 1 || colName === "Representative Name" ? agencyRepsConfig : this.state.tabValue === 2 || colName === "Covered Talent" ? agencyTalentsConfig : null
        let list = this.state.tabValue === 1 || colName === "Representative Name" ? this.state.representative_list : this.state.tabValue === 2 || colName === "Covered Talent" ? this.state.agency_talent_list : null
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            // config: updateTableSortConfigObject(configObjectAllUsers, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            if (this.state.tabValue === 1 || colName === "Representative Name") {
                this.setState({
                    config: updateTableSortConfigObject(config, this.state.isSortByAsc, this.state.sortBy, colName),
                    sortBy: colName,
                    representative_list: tableSortList(colName === "Phone" ? "Number" : "Name", this.getSortNode(colName), list, this.state.isSortByAsc)
                }, () => { this.formatCoveredTalent() });
            } else if (this.state.tabValue === 2 || colName === "Covered Talent") {
                this.setState({
                    agencyTalentsConfig: updateTableSortConfigObject(config, this.state.isSortByAsc, this.state.sortBy, colName),
                    sortBy: colName,
                    agency_talent_list: tableSortList(colName === "Phone" ? "Number" : "Name", this.getSortNode(colName), list, this.state.isSortByAsc)
                });
            }
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Representative Name": return "representative_name";
            case "Covered Talent": return this.state.tabValue === 1 ? "covered_talent" : "talent_name";
            case "representative_name": return "rep_name";
            case "occupation": return "type";
        }
    }

    onChangeHandler = (type, value) => {
        let filteredUser = this.state.tabValue === 1 ? this.state.filterList : this.state.tabValue === 2 ? this.state.talentsFilterList : null
        filteredUser[type] = value
        this.state.tabValue === 1 ? this.setState({ filterList: filteredUser }) : this.state.tabValue === 2 ? this.setState({ talentsFilterList: filteredUser }) : null
    }

    addFilterChangeHandler = (field, value) => {
        let newEntry = [`${field} :${value}`]
        let filterInputs = this.state.filterInputs
        let check = filterInputs?.filter((item, index) => {
            var keyValue = item.toString().split(":");
            if (keyValue[0].trim() === field) {
                filterInputs[index] = newEntry
                return item = newEntry
            }
            // var name = keyValue[0].trim();
            // var value = keyValue[1].trim().toLowerCase();
        })
        if (check?.length === 0) {
            filterInputs.push(newEntry)
        }
        var keyValuePair;
        filterInputs = filterInputs?.filter((item, index) => {
            keyValuePair = item.toString().split(":");
            if (keyValuePair[1] !== "") {
                return item
            }
        })
        this.setState({ filterInputs: filterInputs })
        let config = this.state.tabValue === 1 ? agencyRepsConfig : this.state.tabValue === 2 ? agencyTalentsConfig : null
        let list = this.state.tabValue === 1 ? this.state.backup_representative_list : this.state.tabValue === 2 ? this.state.backup_agency_talent_list : null
        let filteredTableData = this.applyFilterTable(config, list, filterInputs, false)
        this.state.tabValue === 1 ? this.setState({ rep_list_to_display: filteredTableData }) : this.state.tabValue === 2 ? this.setState({ agency_talent_list: filteredTableData }) : null
    }

    applyFilterTable = (config, data, filterValues, filtersNone) => {
        var finalList = [];
        var finalResult = data.slice();
        var primaryKey = config.primaryKey;
        if (!filtersNone) {

            filterValues.map((filter) => {
                var keyValue = filter.toString().split(":");
                var name = keyValue[0].trim();
                var value = keyValue[1].trim().toLowerCase();

                var index = -1; // Object.keys(config?.headings).indexOf((item) => config?.headings[item].headingLabel == name);

                Object.keys(config?.headings).map((item) => {
                    console.log(config?.headings[item])
                    if (config?.headings[item].headingLabel == name) {
                        index = item;
                        return;
                    }
                });

                var nodeName = config?.dataNodes[index];
                var filteredList = data?.filter((item) => {
                    if (nodeName == "Type") {
                        return item[nodeName]?.length > 0 ? item[nodeName]?.some(ele => ele?.toString().toLowerCase().indexOf(value) > -1) : false;
                    }
                    else {
                        return item[nodeName] != null ? item[nodeName].toString().toLowerCase().indexOf(value) > -1 : false;
                    }
                })
                finalResult = filteredList.slice()
            });
        }
        else {
            finalResult = data;
        }
        return finalResult;
    }

    handleDeleteRep = (id, dataItem) => {
        id = !id ? dataItem?.representative_id : id;
        let currentList = this.state.representative_list?.length > 0 ? [...this.state.representative_list] : [];
        currentList?.map(item => {
            if (item.representative_id === id) {
                item['is_delete'] = 1;
                return item;
            }
        })
        this.setState({ representative_list: currentList }, () => {
            this.formatCoveredTalent()
        })
    }

    getRepresentativesList = (objectType, searchString) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then((response) => {
                if (response?.data?.error) {
                    console.log('error from search', repsonse?.data?.error)
                } else {
                    let formattedList = response?.data?.map(i => ({ value: i?.representative_id, text: i?.representative_name, email: i?.email, occupation: i?.occupation, phone: i?.phone, talent_list: i?.talent_list }))
                    this.setState({ representative_options: formattedList, backup_rep_options: response?.data })
                    console.log("post response", response);
                }
            },
                (err) => {
                    console.log("Post agency details error: " + err);
                });
    }

    handleSelectedRep = (newValue) => {
        if (newValue) {
            let selectedRepData = this.state.representative_options?.find(item => item.value === newValue.value);
            this.setState({ selectedRep: newValue, selectedRepData: selectedRepData })
        }
        else {
            this.setState({ selectedRep: null })
        }
    }

    agencyRepAdd = () => {
        let selectedRep = this.state.backup_rep_options?.find(item => item.representative_id === this.state.selectedRep?.value)
        selectedRep.talent_representative_agency_mtrx_id = null
        selectedRep.type = 'new'
        let current_rep_list = [...this.state.representative_list, selectedRep]
        this.setState({ representative_list: current_rep_list }, () => {
            this.formatCoveredTalent()
        })
    }

    formatCoveredTalent = () => {
        let rep_list_to_display = this.state.representative_list?.map(rep => {
            return {
                "representative_id": rep.representative_id,
                "representative_name": rep.representative_name,
                "talent_id": rep.talent_list?.map(talent => talent.talent_id),
                "covered_talent": rep.talent_list?.map(talent => talent.talent_name).join('\n'),
                "is_delete": rep.is_delete,
                "talent_representative_agency_mtrx_id": rep.type === 'new' ? null : rep.talent_representative_agency_mtrx_id,
                "talent_list": rep?.talent_list
            }
        })
        this.setState({ rep_list_to_display: rep_list_to_display })
    }

    validateErrorFlags = (agencyDetails) => {
        let canSubmit = (agencyDetails?.akas?.length !== 0 ? agencyDetails?.akas?.every(aka => (aka.alias)) : true) &&
            (agencyDetails?.social_list?.length !== 0 ? agencyDetails?.social_list?.every(social => (social?.social_media_type && social?.social_media_account)) : true) &&
            (!this.checkMultiplePrimary(agencyDetails.social_list)) &&
            //(talentDetails?.performer_notes ? talentDetails?.performer_notes?.length <= talentPersonalLimit.performerNotes : true) &&
            (!this.checkMultiplePrimary(agencyDetails.location_list)) &&
            (!this.checkMultiplePrimary(agencyDetails.akas))
        return canSubmit;
    }

    checkMultiplePrimary = (list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        if(multiplePrimary?.length > 1){
            multiplePrimary?.map((item,index)=>{
                if(index!=0){
                    item.is_primary=0;
                }
            })
              return false;  
            }
        
        return multiplePrimary?.length > 1
    }

    handleSubmit = () => {
        this.setState({ postingAgency: true, postInitiated: true }, () => {
            this.props.setPostFlag(true);
        })
        let agencyDetails = this.state.agencyDetails
        agencyDetails.social_list = agencyDetails?.social_list?.filter(i => i?.object_id !== null || i?.object_type != null || i?.value != null)?.map((item) => ({
            "is_delete": item?.is_delete,
            "is_primary": item?.is_primary,
            "social_media_account": item?.value,
            "agency_social_media_id": item?.type === 'new' ? null : item?.object_id,
            "social_media_type": item?.object_type
        }))
        agencyDetails?.location_list?.find(loc => {
            loc.agency_location_id = loc.type === 'new' ? null : loc.agency_location_id,
                loc.address = loc.actual_address,
                loc.city = loc?.city,
                loc.state = loc?.state,
                loc.zip = loc?.zip,
                loc.country = loc?.country,
                loc.phone_list = loc.phone_list?.map((item, index) => {
                    if (item?.object_type != null && item?.value != null) {
                        return {
                            "agency_location_phone_id": item.object_id,
                            "phone_type": item.object_type,
                            "phone": item.value,
                            "is_primary": item.is_primary,
                            "is_delete": item.is_delete,
                            "ext": item?.ext?.toString()
                        }
                    }
                })
        })
        agencyDetails.representative_list = this.state.rep_list_to_display
        let canSubmit = this.validateErrorFlags(agencyDetails);
        if (canSubmit) {
            CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyDetails`, agencyDetails, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        console.log("Error posting Agency Details data");
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                    }
                    else {
                        this.props.setPostFlag(false);
                        // this.notificationComponent(true, "success");
                        if (this.props.isProfileChanged) {
                            this.props.uploadProfile(response.data[0].agency_id);
                        }
                        if(this.props?.setNewAgency){
                            this.props?.setNewAgency(this.state.agencyDetails,response.data[0].agency_id);
                        }
                        if(this.props?.handleHideRepoPopup){
                            this.props?.handleHideRepoPopup();
                        }
                        if(this.props?.handleShowRepresentativePopup){ 
                            if(this.props?.selectedRep?.value || this.props?.selectedRep?.text){
                                this.props?.handleShowRepresentativePopup(this.props?.selectedRep?.value,this.props?.selectedRep?.text);
                            }
                        }
                        if(this.props?.handleShowAgencyPopup){
                            this.props?.handleShowAgencyPopup(this.state.agencyDetails?.agency_id,this.state.agencyDetails?.agency_name);
                        }
                        this.getAgencyDetails(this.state.agencyDetails?.agency_id)
                        this.props.fieldChanged(true, "success");
                        console.log("Posted successfully", this.state.agencyDetails)
                    }
                },
                    (err) => {
                        this.props.setPostFlag(false);
                        this.props.fieldChanged(true, "fail");
                        console.log("Error posting Agency Details data", err)
                    })
        }
    }

    getAgencyTalents = (agency_id) => {
        this.setState({ fetchingAgencyTalents: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureAgencyTalentList?id=${agency_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Error getting Agency Talent Details data");
                    this.setState({ fetchingAgencyTalents: false })
                }
                else {
                    let formattedList = [], talents = []
                    talents = response.data[0]?.talent_list
                    formattedList = talents?.map(item => item.representative)
                    let agency_talents = formattedList?.reduce((t1, t2) => t1?.concat(t2), []);
                    this.setState({ fetchingAgencyTalents: false, agency_talent_list: handlePaginationList(this.state.page, this.state.rowsPerPage, agency_talents), backup_agency_talent_list: agency_talents },
                        () => {
                            this.handleTableColumnClick("Covered Talent")
                        })
                    console.log("Get successfull", agency_talents)
                }
            },
                (err) => {
                    this.setState({ fetchingAgencyTalents: false });
                    console.log("Error getting Agency Talent Details data", err);
                })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            agency_talent_list: handlePaginationList(newPage, this.state.rowsPerPage, this.state.backup_agency_talent_list)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            agency_talent_list: handlePaginationList(0, parseInt(event.target.value, 10), this.state.backup_agency_talent_list)
        });
    }

    onLocationSave = (currentLocation) => {
        let agencyDetails = this.state.agencyDetails, location_list = []
        if (currentLocation?.actual_address && currentLocation?.country) {
            let filteredPhone = currentLocation?.phone_list.filter(item => item.object_type != null && item.value != null)
            let curr_state = currentLocation?.state
            if (curr_state?.includes('-')) {
                let splitArray = curr_state?.split("-")
                curr_state = splitArray[1]
              }
            let new_location = {
                "type": currentLocation?.type,
                "agency_location_id": currentLocation?.agency_location_id ? currentLocation?.agency_location_id : Date.now(),
                "location_name": currentLocation?.location_name || "--",
                "address_type": "Work",
                "actual_address": currentLocation?.actual_address,
                // "address": formattedAddress,
                "address": `${currentLocation["actual_address"] ? currentLocation["actual_address"] : ''}${currentLocation["city"] ? (curr_state || currentLocation["zip"]) ? '\n' + currentLocation["city"] + ', ' : '\n' + currentLocation["city"] : '\n' + ''}${curr_state ? '' + curr_state + ' ' : ''}${currentLocation["zip"] ? currentLocation["zip"] : ''}${currentLocation["country"] && (currentLocation["country"] !== "United States" && currentLocation["country"] !== "USA" && currentLocation["country"] !== "US") ? (currentLocation["city"] || curr_state || currentLocation["zip"]) ? '\n' + currentLocation["country"] : currentLocation["country"]: ''}`,
                "city": currentLocation?.city,
                "zip": currentLocation?.zip,
                "state": currentLocation?.state,
                "country": currentLocation?.country,
                "is_primary": currentLocation.is_primary,
                "is_delete": currentLocation.is_delete,
                "phone_list": filteredPhone,
                // "phone": currentLocation?.phone_list?.map((phone, index) => (index ? ', ' : '') + phone?.object_type + ':' + phone?.value) === 'null' ? ["--"] : (currentLocation?.phone_list?.map((phone, index) => (index ? ', ' : '') + phone?.object_type + ':' + phone?.value))
                "phone": currentLocation?.phone_list?.map((phone, index) => `${phone["object_type"] ? phone["object_type"] : ''}${phone["value"] ? ' ' + phone["value"] : ' '}`)
            }
            let newAddressList = agencyDetails.location_list?.filter(item => item.is_delete === 0)
            if (newAddressList?.length === 0) {
                new_location.is_primary = 1
            }
            let canSave = !this.checkMultiplePrimary(new_location?.phone_list) && (new_location?.country ? true : false) && (new_location?.location_name.length > 0 ? true : false) && (new_location?.address.length > 0 ? true : false) &&
                (new_location?.phone_list?.every(phone =>
                    !(phone.phone) ? true :
                        !phone.phone_id ? (validatePlusCharacter((phone.phone.trim()).charAt(0)) ?
                            validateIntlNumber(phone.phone) && phone.phone.length <= sizeLimits.phoneLimitIntl
                            :
                            (validateUSNumber(phone.phone) || validatePhoneNumber(phone.phone)) && phone.phone.length <= sizeLimits.phoneLimitUS) : true));
            if (canSave) {
                let index = agencyDetails.location_list.findIndex(item => item.agency_location_id === currentLocation.agency_location_id)
                index >= 0 ? agencyDetails.location_list[index] = new_location : agencyDetails?.location_list.push(new_location);
                location_list = agencyDetails.location_list
            }
            else if (canSave) {
                agencyDetails?.location_list.push(new_location);
                location_list = agencyDetails.location_list
            }
            this.setState({ agencyDetails: agencyDetails, agencyLocationList: location_list }, () => {
                this.closeLocationPopOver()
                this.closeLocationPopOver()
                console.log("Location List: ", this.state.agencyLocationList)
            })
        }
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.setState({ saveFlag: false }, this.closPopup)
        }
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }

    editLocation = (list, index, id, dataItem) => {
        let agencyDetails = this.state.agencyDetails
        let locationDets = agencyDetails.location_list[index]
        formattedPhoneList = locationDets?.phone_list?.map(i => {
            object_id = i.agency_location_phone_id,
                object_type = i.phone_type,
                value = i.phone,
                is_primary = i.is_primary,
                is_delete = i.is_delete,
                ext = null
        })
        locationDets.phone_list = formattedPhoneList
        this.setState({ selectedLocation: locationDets })
    }

    openLocationPopOver = () => {
        this.setState({ openLocationPopOver: true })
    }

    closeLocationPopOver = () => {
        location = this.state.initialLocationConfig
        location.phone_list = []
        location.phone_list = [JSON.parse(JSON.stringify({ ...initialPhoneObject }))]
        location.phone_list = []
        location.phone_list = [JSON.parse(JSON.stringify({ ...initialPhoneObject }))]
        this.setState({ locationConfig: this.state.initialLocationConfig, openLocationPopOver: false })
    }

    handleShareClick = (id, dataItem, dataItem2) => {
        let agencyLocationList = this.state.added_location_list
        let selectedAgencyLocation = agencyLocationList.find(item => item.agency_location_id === dataItem.agency_location_id)
        if (selectedAgencyLocation?.phone_list?.length === 0) {
            selectedAgencyLocation?.phone_list?.push(JSON.parse(JSON.stringify({ ...initialPhoneObject })))
        }
        if (typeof(selectedAgencyLocation.country) === 'string'){
        selectedAgencyLocation.country = { value: selectedAgencyLocation.country, text: selectedAgencyLocation.country }
        }
        if(typeof(selectedAgencyLocation.state) !== 'string'){ selectedAgencyLocation.state = selectedAgencyLocation.state?.value; }
        selectedAgencyLocation.country?.value === 'United States' || selectedAgencyLocation.country?.value === 'United States' || selectedAgencyLocation.country?.value === 'United States' ?
            selectedAgencyLocation.state ? selectedAgencyLocation.state = selectedAgencyLocation?.state?.includes("-") ? { value: selectedAgencyLocation.state?.split("-")[0].trim(), text: selectedAgencyLocation.state?.split("-")[0].trim() } : { value: selectedAgencyLocation.state, text: selectedAgencyLocation.state } : null : null
        location = selectedAgencyLocation
        this.setState({ selectedLocation: selectedAgencyLocation }, () => {
            this.openLocationPopOver()
        })
    }

    handleMarkAsPrimary = (id, dataItem, dataItem2) => {
        let agencyDetails = this.state.agencyDetails
        let location_list = agencyDetails?.location_list?.map(item => {
            if (item.agency_location_id === id) {
                item.is_primary = 1
            }
            else {
                item.is_primary = 0
            }
            return item
        })
        agencyDetails.location_list = location_list
        this.setState({ agencyDetails: agencyDetails });
    }

    deleteLocation = (id, dataItem, dataItem2) => {
        let agencyDetails = this.state.agencyDetails
        let agencyLocationList = this.state.agencyLocationList
        let index = agencyDetails?.location_list.findIndex(item => item.agency_location_id === dataItem.agency_location_id)
        agencyDetails.location_list[index].is_delete = 1
        agencyLocationList[index].is_delete = 1
        this.setState({ agencyDetails: agencyDetails, agencyLocationList: agencyLocationList })
    }
    
    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (
            <div className="agentContainer">
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                <input className="hiddenButton" type="button" ref={this.props.submitButtonRef} onClick={() => this.handleSubmit()}></input>
                {this.getTabComponent()}
            </div>
        );
    }
}

export default withUserContext(AgentContainer);