import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBTooltip } from "mdb-react-ui-kit";
import React, { Component } from 'react'
import { CircularProgress } from "@material-ui/core";
import { projectTabList, productionCompanyConfig } from './config'
import HorizontalTabComponent from '../../Common/HorizontalTab/HorizontalTabComponent';
import NewProduction from '../Production/NewProduction';
import "./ProjectDetails.scss"
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import SelectField from '../SharedComponents/SelectField/SelectField';
import * as Constants from '../../../constants/constants';
import CasterService from '../../../services/service';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import Performer from '../Performers/Performer';
import TableComponent from "../SharedComponents/Table";
import ProjectCrew from './ProjectCrew';
import Modal from '../../Common/MainViewModalLayout/Modal/Modal'
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import Location from '../Location/Location';
import BillingSection from './BillingSection'
import WorkWeek from './WorkWeek'
import StatusDates from './StatusDates'
import Files from './Files';
import { withUserContext } from "../../../contexts/UserContext";
import { performerConfig } from '../Performers/Config';
import {
    BrowserRouter as Router, withRouter
} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
let backupTabIndex = null;
class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: this.props?.match?.params?.projectId,
            fetchingProjectDetails: false,
            showCollapsible: true,
            tabList: projectTabList,
            tabValue: 0,
            reportOptions: [],
            projectDetails: null,
            showNewProduction: false,
            showNotifSuccess: false,
            showLocDeleted: false,
            showContractDeleted: false,
            showNotifFail: false,
            openWorkWeekModal: false,
            allWorkWeekData: [],
            reloadWorkWeeks: false,
            reloadStatusDates: false,
            openStatusDateModal: false,
            contract_type: null,
            selectedProductionCompany: null,
            boxURL: null,
            tabToDisplay: this.props?.tabToDisplay ? this.props?.tabToDisplay : null,
            discardFlagParent: false,
            showdiscardNotificationParent: false,
            selectedProdCompanies: []
        }
        this.buttoncRef = React.createRef();
        this.locationButtonRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'ProjectDetails') {
            const newProjectId = Number(this.props?.match?.params?.projectId);
            const currentProjectId = Number(this.state.projectId);            
            if (newProjectId !== currentProjectId) {
              this.setState({ projectId: newProjectId }, () => {
                this.getProjectDetails();
                this.props?.toggleRefreshPage('');
              });
            } else {
                this.getProjectDetails("refreshPerformerTable");
                this.props?.toggleRefreshPage('');
            }
        }
    }
    sendDiscardChangeNotification = (value) => {
        this.setState({ showdiscardNotificationParent: value, discardFlag: value });
    }
    handleTabChange = (index) => {
        backupTabIndex = index;
        if (this.state.discardFlagParent !== index) {
            if (this.state.discardFlagParent === true) {
                this.sendDiscardChangeNotification(true);
            } else if (this.state.showdiscardNotificationParent === false) {
                this.setState({ tabValue: index })
            }
        } else {
            this.setState({ tabValue: index })
        }
    }

    handleHideProductionPopup() {
        this.setState({ showNewProduction: false })
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showNotifSuccess: value });
            case "fail": return this.setState({ showNotifFail: value });
            case "Location": return this.setState({ showLocDeleted: value });
            case "Contract": return this.setState({ showContractDeleted: value });
        }
    }
    handleResetNotify = () => {
        this.setState({
            showNotifFail: false, showNotifSuccess: false,
            showLocDeleted: false, showContractDeleted: false
        });

    }

    getTabComponent = () => {
        switch (this.state?.tabValue) {
            case 0: return <Performer
                project_id={this.state.projectId}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                contract_type={this.state.contract_type}
                notificationComponent={this.notificationComponent.bind(this)}
                production_companies={this.state?.projectDetails?.production_companies}
                boxURL={this.state.boxURL}
                selectedProductionCompany={this.state.selectedProdCompanies}
                updateDiscardChangesFlagParent={this.updateDiscardChangesFlagParent.bind(this)} showdiscardNotificationParent={this.state.showdiscardNotificationParent} refreshDiscardChangesFlagParent={this.refreshDiscardChangesFlagParent.bind(this)}
                {...this.props} />
            case 1: return <BillingSection project_id={this.state.projectId} />;
            case 2: return <Location project_id={this.state.projectId}
                locationButtonRef={this.locationButtonRef}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                notificationComponent={this.notificationComponent.bind(this)} />
            case 3: return <WorkWeek project_id={this.state.projectId} reloadWorkWeeks={this.state.reloadWorkWeeks} updateAllWorkWeek={(data) => { this.setState({ allWorkWeekData: data }) }} />;
            case 4: return <ProjectCrew
                project_id={this.state.projectId}
                notificationComponent={this.notificationComponent.bind(this)}
                navigationCrewDetail={this.props?.obj} // this additional parameter for redirecting to crew page from dashboard
                navigationName={this.props?.navigationField}// this additional parameter for redirecting to crew page from dashboard
                buttoncRef={this.buttoncRef}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                {...this.props}
            />;
            case 5: return <StatusDates project_id={this.state.projectId} reloadStatusDates={this.state.reloadStatusDates} />;
            case 6: return <Files project_id={this.state.projectId} />;

        }
    }
    handleButtonClick() {
        this.buttoncRef.current.click();
    }
    handleLocationButtonClick() {
        this.locationButtonRef.current.click();
    }
    componentDidMount() {
        if (this.props?.navigationField === 'crew_name' && this.props?.tabToDisplay >= 0) {
            this.setState({ tabValue: this.props?.tabToDisplay ? this.props?.tabToDisplay : 0 })
        }
        this.getProjectDetails();
        this.getDropDownValues(performerConfig)
    }
    getProjectDetails(origin=null) {
        this.setState({ fetchingProjectDetails: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeatureProject?project_id=${this.state?.projectId}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response?.data?.error || !response?.data) {
                    this.setState({ fetchingProjectDetails: false })
                }
                else {
                    if(origin==="refreshPerformerTable"){
                        this.setState({selectedProdCompanies:[],fetchingProjectDetails: false, projectDetails: response?.data?.[0], boxURL: response?.data?.[0]?.url, contract_type: response?.data?.[0]?.contract_type})
                    }else{
                        this.setState({ fetchingProjectDetails: false, projectDetails: response?.data?.[0], boxURL: response?.data?.[0]?.url, contract_type: response?.data?.[0]?.contract_type })
                    }
                }

            }, err => {
                this.setState({ fetchingProjectDetails: false })
                console.log("error from project details")
            })

    }

    getDropDownValues = (config, v) => {
        config?.headings?.map(data => {
            if (data.inputType === "select" || data.inputType === "daterange") {
                if (data.tableName != 'none') {
                    CasterService.getData(Constants.CasterServiceBaseUrl + '/casterFeatureLookup?objectType=' + data.tableName)
                        .then(response => {
                            let formattedList = response.data?.map(item => ({ value: item.lookup_value, label: item.lookup_value }));
                            this.setState({ [data.selectOptions]: formattedList });
                        },
                            (err) => {

                            })
                }
            }
        })
    }
    // static getDerivedStateFromProps(props, state) {
    //     if(props?.tabValue !== state?.tabValue){
    //         return {
    //             tabValue:props?.tabValue
    //         }
    //     }
    // }
    toggleCollapsible() {
        this.setState(prevState => ({
            ...prevState,
            showCollapsible: !prevState?.showCollapsible
        }))
    }
    getAliases() {
        return this.state?.projectDetails?.aliases?.filter(item => item?.is_aka === 1).map(i => i?.alias_name)?.join(", ") || ''
    }
    redirectToDashboard() {
        this.props.history.push(`/caster-feature`)
    }
    navigationCallback = (obj, node, e) => {
        let tempSelectedelems = document.querySelectorAll(".selectedProdCompany");
        [].forEach.call(tempSelectedelems, function (el) {
            el.classList.remove("selectedProdCompany");
        });
        let selectedProdCompanies = [];
        if (this.state.selectedProdCompanies.length > 0) {
            if (this.state.selectedProdCompanies[0]?.project_production_company_id === (obj.project_production_company_id)) {
                e.target.classList.remove("selectedProdCompany");
                selectedProdCompanies=[];
            } else {
                e.target.classList.add("selectedProdCompany");
                selectedProdCompanies.splice(0, 0, obj);
            }
        } else {
            e.target.classList.add("selectedProdCompany");
            selectedProdCompanies.splice(0, 0, obj);
        }


        // let selectedProductionCompany = [`Production Company: ${obj.production_company}`]
        // this.setState({ selectedProductionCompany: selectedProductionCompany }, () => {
        //     this.props?.toggleRefreshPage('ProductionCompanyFilters')
        // })
        this.setState({ selectedProdCompanies });
    }

    updateDiscardChangesFlagParent = (value) => {
        this.setState({ discardFlagParent: value });
    }
    refreshDiscardChangesFlagParent = (value) => {
        if (value === "confirm") {
            this.setState({ discardFlagParent: false, showdiscardNotificationParent: false }, () => { this.handleTabChange(backupTabIndex) });
        } else {
            this.setState({ discardFlagParent: true, showdiscardNotificationParent: false });
        }

    }
    render() {
        let severity = this.state.showNotifFail ? "error" : this.state.showNotifSuccess ? "success" : this.state.showRemoveProfile ? "success" : this.state.showLocDeleted ? "info" : this.state.showContractDeleted ? "info" : "";
        let messageNotification = this.state.showNotifSuccess ? "Saved successfully!" : this.state.showNotifFail ? "Saving changes failed!" : this.state.showLocDeleted ? "Location Deleted" : this.state.showContractDeleted ? "Contract Deleted Successfully" : "";

        return (
            <MDBContainer fluid className='ProjectDetailsContainer'>
                <NotificationComponent open={this.state.showNotifSuccess || this.state.showNotifFail || this.state.showLocDeleted || this.state.showContractDeleted} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                {this.state?.fetchingProjectDetails ?
                    <div className='ContentLoader'>
                        <CircularProgress color="inherit" />
                    </div>
                    :
                    <>

                        <MDBRow>
                            <MDBCol md={11}>
                                <div className='headerContainer'>
                                    <h3 className='upperCase noLeftPadding'>{this.state?.projectDetails?.project_name}</h3>
                                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <BasicButton
                                        text="Edit"
                                        icon="pen"
                                        onClick={() => this.setState({ showNewProduction: true })}
                                        // onClick={() => { }}
                                        variant="outlined"
                                    />}
                                    <SelectField
                                        options={this.state?.reportOptions}
                                        placeHolderText="Reports"
                                    />

                                </div>
                            </MDBCol>
                            <MDBCol md={1} className="crossIcon">
                                <MDBIcon
                                    icon='times'
                                    className=''
                                    onClick={this.redirectToDashboard.bind(this)}
                                />
                            </MDBCol>
                            <MDBCol md={12}>
                                <div className='upperCase smallText'><b>AKA(S)</b> {this.getAliases()}</div>
                            </MDBCol>
                            <MDBCol md={12}>
                                <p className='statusRow upperCase smallText'>
                                    <div className='rightBorder'>{this.state?.projectDetails?.status}</div>
                                    <div className=''>{this.state?.projectDetails?.assigned_to_name ? this.state?.projectDetails?.assigned_to_name : 'Not Assigned'}</div>
                                </p>
                            </MDBCol>
                            <MDBCol md="12">
                                <h6 className='cursorPointer header labelText1' onClick={() => this.toggleCollapsible()}>Expand Project Details <MDBIcon icon={this.state?.showCollapsible ? 'angle-up' : 'angle-down'} />
                                </h6>
                            </MDBCol>
                        </MDBRow>
                        {this.state?.showCollapsible &&
                            <div className='detailsSection'>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <div className='details'>
                                            <div className='dataContainer'>
                                                <div className='header'>Studio</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.studio_name || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>SAP/GL Prod.</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.gl_no_prod || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>SAP/GL Dev.</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.gl_no_dev || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>Start Date</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.start_date || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>Project Type</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.project_type || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>SAG Prod. Id</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.sag_id || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>Wrap Date</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.wrap_date || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>Release Date </div>
                                                <div className='dataValue'>{this.state?.projectDetails?.release_date || '--'}</div>
                                            </div>
                                            <div className='dataContainer'>
                                                <div className='header'>Contract Type</div>
                                                <div className='dataValue'>{this.state?.projectDetails?.contract_type || '--'}</div>
                                            </div>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md={4} className="projectDetailNotes">
                                        <div className='header'>Notes</div>
                                        {this.state?.projectDetails?.notes ? this.state?.projectDetails?.notes?.length>500 ? <MDBTooltip wrapperProps={{ color: 'secondary' }} placement={"left"} title={ReactHtmlParser(this.state?.projectDetails?.notes)}><span>{ReactHtmlParser(this.state?.projectDetails?.notes?.substring(0,500)+"...")}</span></MDBTooltip>:<div>{ReactHtmlParser(this.state?.projectDetails?.notes)}</div> : <div>--</div>}
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        <div className='productionCompany'>
                                            <div className='header'>Production Companies</div>
                                            <TableComponent
                                                tableType="striped"
                                                // tableMode="light"
                                                list={this.state?.projectDetails?.production_companies || []}
                                                config={productionCompanyConfig}
                                                hyperLinkNavigationCallback={this.navigationCallback}
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        }
                        <MDBRow>
                            <MDBCol md={10} sm={12}>
                                <HorizontalTabComponent
                                    tabList={this.state.tabList}
                                    handleTabChange={this.handleTabChange.bind(this)}
                                    selectedTab={this.state.tabValue}
                                    tabValue={this.state.tabValue}>
                                </HorizontalTabComponent>
                            </MDBCol>
                            {this.state?.tabValue == 4 &&
                                <MDBCol md={2} sm={6} className='d-flex justify-content-end align-self-center'>
                                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <BasicButton
                                        text={this.state?.tabValue == 4 ? 'crew' : ''}
                                        onClick={this.handleButtonClick.bind(this)}
                                        icon={'plus-circle'}
                                        variant="outlined"
                                        className="FR MR1"
                                    />}
                                </MDBCol>}
                            {this.state?.tabValue == 2 &&
                                <MDBCol md={2} sm={6} className='d-flex justify-content-end align-self-center'>
                                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <BasicButton
                                        text={this.state?.tabValue == 2 ? 'Location' : ''}
                                        onClick={this.handleLocationButtonClick.bind(this)}
                                        icon={'plus-circle'}
                                        variant="outlined"
                                        className="FR MR1"
                                    />}
                                </MDBCol>}
                            {this.state?.tabValue == 3 &&
                                <MDBCol md={2} sm={6} className='d-flex justify-content-end align-self-center'>
                                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <BasicButton
                                        text={'Work Week'}
                                        className="FR MR1"
                                        onClick={() => { this.setState({ openWorkWeekModal: true }) }}
                                        icon={'plus-circle'}
                                        variant="outlined"
                                    />}
                                </MDBCol>}
                            {this.state?.tabValue == 5 &&
                                <MDBCol md={2} sm={6} className='d-flex justify-content-end align-self-center'>
                                    {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <BasicButton
                                        text={'Status Date'}
                                        className="FR MR1"
                                        onClick={() => { this.setState({ openStatusDateModal: true }) }}
                                        icon={'plus-circle'}
                                        variant="outlined"
                                    />}
                                </MDBCol>}
                        </MDBRow>
                        {this.getTabComponent()}
                    </>
                }
                {this.state.showNewProduction ?
                    <Modal
                        // id={24}
                        id={this.state.projectId}
                        // akas={this.state?.selectedCrew?.aka}
                        open={this.state.showNewProduction}
                        handleClose={this.handleHideProductionPopup.bind(this)}
                        // tabList={crewNavList}
                        modalName={"Project"}
                        headerText={"Project"}
                        mode="Edit"
                        toggleRefreshPage={this.props?.toggleRefreshPage}
                        {...this.props}
                    // isProfileDiv={true}
                    // addButtonText={"New Crew"}
                    // objectType={'CREW'}
                    // objectId={this.state?.selectedCrew?.value || 1}
                    // dataUrl='casterFeatureCrewDetails'
                    // headerName={this.state.selectedCrew?.headerName}
                    /> : null
                }
                {this.state?.openWorkWeekModal &&
                    <Modal
                        modalName='Work Week'
                        projectId={this.state.projectId}
                        handleClose={() => { this.setState({ openWorkWeekModal: false }) }}
                        mode={'new'}
                        open={this.state.openWorkWeekModal || false}
                        headerText={"Work Week"}
                        workWeekData={null}
                        allWorkWeeks={this.state.allWorkWeekData}
                        toggleRefreshPage={() => this.setState({ reloadWorkWeeks: !this.state.reloadWorkWeeks })}
                    />}
                {this.state?.openStatusDateModal &&
                    <Modal
                        modalName='Status Date'
                        projectId={this.state.projectId}
                        handleClose={() => { this.setState({ openStatusDateModal: false }) }}
                        mode={'new'}
                        open={this.state.openStatusDateModal || false}
                        headerText={"Status Date"}
                        toggleRefreshPage={() => this.setState({ reloadStatusDates: !this.state.reloadStatusDates })}
                    />}
            </MDBContainer>
        )
    }
}

export default withRouter(withUserContext(ProjectDetails));